import React, { useEffect } from 'react';
import { motion } from "framer-motion"
import { ReactComponent as Logo } from '../../svg/logo_waterstand_wmask.svg';
import $ from 'jquery'

export const ScreenLoading = (props) =>
{	
	useEffect(() =>
	{
		$('.logo.waterstand').addClass('is-in');
	}
	, []);

	return (
		<div className='loading-screen' id='loading-screen' data-loaded={props.loaded || 0}>
			<div className='loader-wrapper'>
				<div className='logo waterstand'>
					<Logo/>
				</div>
				<div className='ripple'>
					<div/>
					<div/>
					<div/>
					<div/>
					<div/>
					<div/>
					<div/>
					<div/>
					<div/>
				</div>
			</div>
		</div>
	);
}

export default ScreenLoading;