export const DOCUMENT_TITLE = 'WATER STAND';

export const MENU = 
{
	CATALOG: '商品カタログ',
	SETUP: '設置方法・サポートサービス',
	SETUP1: '設置方法',
	SETUP2: 'サポートサービス',
	ORDER: 'お申し込み・料金について',
	ORDER1: 'お申し込み',
	ORDER2: '料金について',
	PRICELIST: '機種別料金表',
	SHOWROOM: 'ショールーム',
	HELP: 'オンラインお申込み',
}

export const LABELS = 
{
	ITEM_VIDEO: '紹介動画を見る',
	PRICE_RENTAL: 'レンタル料金',
	PRICE_INSTALLATION: '初回設置費',
	INCLUDED_INSTALLATION: '初回設置費に含まれるもの',
}

export const URLS = 
{
	LIVECALL: 'https://waterstand.jp/products_list.html',
	ORDER: 'https://waterstand.jp/products_list.html',
}

export const WATER =
{
	COLD: '冷水',
	ROOM: '常温水',
	HOT: '温水'
};