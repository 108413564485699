
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import $ from "jquery";
class ScrollTopOnRoute extends Component
{
	componentDidUpdate(prevProps)
	{
		if (this.props.location !== prevProps.location)
		{
			$('.screen:nth-child(n+2)').scrollTop(0);
			setTimeout(() =>
			{
				$(document).scrollTop(0);
			}
			, 300);
		}
	}

	render() {
		return <React.Fragment/>
	}
}

export default withRouter(ScrollTopOnRoute)