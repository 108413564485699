import prefectures from '../../json/params_prefectures.json';

export const parseAreas = () =>
{
	const areas = [];

	prefectures.forEach((e, i) =>
	{
		if (!areas[e.area_id - 1])
		{
			areas[e.area_id - 1] =
			{
				name: e.area,
				id: e.area_id,
				prefectures: [e]
			};
		}
		else
		{
			areas[e.area_id - 1].prefectures.push(e);
		}
	}
	);

	return areas;
}

export const parseShowroomData = (data) =>
{
	const results = {};

	results.areas = [];
	results.prefectures = [];

	prefectures.forEach((e, i) =>
	{
		e.num_showrooms = 0;
		e.showrooms = [];

		results.prefectures.push(e);

		if (!results.areas[e.area_id - 1])
		{
			results.areas[e.area_id - 1] =
			{
				name: e.area,
				id: e.area_id,
				num_showrooms: 0,
				prefectures: [e],
				showrooms: []
			};
		}
		else
		{
			results.areas[e.area_id - 1].prefectures.push(e);
		}
	}
	);

	data.forEach((e, i) =>
	{
		if (results.areas[e.area_id - 1])
		{			
			results.areas[e.area_id - 1].num_showrooms ++;
			results.areas[e.area_id - 1].showrooms.push(e);
		}

		let pi = results.prefectures.findIndex((e2) => e2.en == e['prefecture-en']);

		if (pi > -1)
		{
			results.prefectures[pi].num_showrooms++;
			results.prefectures[pi].showrooms.push(e);
		}
	}
	);

	return results;
}