import React, { Component } from 'react';
import { AppMainContext } from '../../App';
import { Font, Btn, BtnLabel } from './_UI_Common';
import DisplacementSlider from '../../js/_classes/DisplacementSlider';
import parse from 'html-react-parser';

export class TopSlider extends Component
{
	constructor(props)
	{
		super(props);

		this.slider = null;
	}

	makeLIs(imgs)
	{
		const ul = [];

		for (let i = 0; i < imgs.length; i++)
		{
			ul[i] = (
				<li key={i} data-index={i}>
					<img src={imgs[i]} alt={''}/>
				</li>
			);
		}
 
		return (
			<ul className='top-slider' id='top-slider'>{ul}</ul>
		);
	}

	initSlider()
	{
		const sliderParams = 
		{
			el: 'section.slider',
			container: '#top-slider',
			//srcSize: { w:1500, h:1500 * 300/768 },
			srcSize: { w:2400, h:960 },
			map_img: '/img/top/bg_water_displacement_2.jpg',
			imgURLs: this.props.imgs
		};

		this.slider = new DisplacementSlider(sliderParams);
	}

	componentDidMount()
	{
		this.initSlider();
	}

	componentWillUnmount()
	{
		if (this.slider) this.slider.unmount();
	}

	render()
	{
		const use_li = false;

		let slider;
		if (use_li)
		{
			slider = this.makeLIs(this.props.imgs);
		}
		else
		{
			slider = (
			<div className='top-slider' id='top-slider'/>
			);
		}

		return (
			<section className='slider'>
				<h1 className="app-title">
					<div className='logo-wrap'>
						<div className='logo'>
							<img src='/img/logo_waterstand.svg' alt='WATER STAND'/>
						</div>
					</div>
				</h1>
				{slider}
			</section>
		);
	}
}

export const CatalogBtn = (props) =>
{
	const makeItemUL = (count) =>
	{
		let items = [];
		let l = (count === undefined)?props.items.length:count;

		for (let i = 0; i < l; i++)
		{
			let e = props.items[i];
			let img = '/' + (e && e.img)?e.img:'';

			var item = (
				<li className="item" key={i} data-index={i}>
					<img src={img} alt={e.name}/>
				</li>
			)

			items.push(item);
		}

		return items;
	}

	return (
		<Btn c='catalog c-b' n='catalog' id='catalog-btn' to={props.to}>
			<div className='col left'>
				<BtnLabel icon='book'>
					<Font n='jp1'>カタログを見る</Font>
				</BtnLabel>
			</div>
			<div className='col right'>
				<div className="items-wrap">
					<ul className='items main'>
						{makeItemUL()}
					</ul>
					<ul className='items clone'>
						{makeItemUL(8)}
					</ul>
				</div>
			</div>
		</Btn>
	);
}

export const ThumbVideoBtn = (props) =>
{
	const {videoControl} = React.useContext(AppMainContext);

	const video_id = props['video-id'];

	return (
		<button className='btn video c-b' data-video-id={video_id} onClick={() => videoControl.open(video_id)}>
			<div className='inner' style={ {backgroundImage: "url('" + props.img + "')"} }>
				<BtnLabel icon='youtube'>
					<Font n='jp1'>{parse(props.title)}</Font>
				</BtnLabel>
				<div className='bg' style={ {backgroundImage: "url('" + props.img + "')"} }/>
			</div>
		</button>
	);
}