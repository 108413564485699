import * as THREE from "three";
import { gsap, Cubic, Linear } from 'gsap';

export default class DisplacementSlider
{
	constructor(params)
	{
		if (params.el === null || params.container === null) return null;

		this.bindAll();

		//Size of canvas
		this.el = document.querySelector(params.el);
		//Canvas wrapper
		this.container = document.querySelector(params.container);

		this.imgURLs = [];
		this.map_img = '';

		this.srcSize =  { w:1600, h:900 };

		this.interval = 5.0;
		this.transitionDuration = 2.0;

		this.scaleEnd = 1.2;

		for (let key in params)
		{
			if (key === 'el' || key === 'container') continue;
			this[key] = params[key];
		}

		this.renderer = null;
		this.scene = null;
		this.clock = null;
		this.camera = null;	

		this.data =
		{
			current: 0,
			next: 1,
			total: 0,
			delta: 0
		};

		this.state =
		{
			animating: false,
			initial: true
		};

		this.textures = null;

		this.vertexShader = `
			varying vec2 vUv;

			void main() {
				vUv = uv;
				gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
			}
		`;
		this.fragmentShader = `
			varying vec2 vUv;

			uniform sampler2D texture1;
			uniform sampler2D texture2;
			uniform sampler2D disp;

			uniform float dispPower;
			uniform float intensity;

			uniform float scale1;
			uniform float scale2;
			
			void main() {
				vec2 uv = vUv;

				float scale1 = 1.0 / scale1;
				float scale2 = 1.0 / scale2;

				vec2 scaleCenter = vec2(0.5, 0.5);
				vec2 uv1 = (uv - scaleCenter) * scale1 + scaleCenter;
				vec2 uv2 = (uv - scaleCenter) * scale2 + scaleCenter;

				vec4 disp1 = texture2D(disp, uv1);
				vec2 dispVec1 = vec2(disp1.x, disp1.y);
				vec4 disp2 = texture2D(disp, uv2);
				vec2 dispVec2 = vec2(disp2.x, disp2.y);
				
				vec2 distPos1 = uv1 + (dispVec1 * intensity * dispPower);
				vec2 distPos2 = uv2 + (dispVec2 * -(intensity * (1.0 - dispPower)));
				
				vec4 _texture1 = texture2D(texture1, distPos1);
				vec4 _texture2 = texture2D(texture2, distPos2);
				
				gl_FragColor = mix(_texture1, _texture2, dispPower);
			}
		`;

		this.init();
	}

	init()
	{
		this.scene = new THREE.Scene();
		this.clock = new THREE.Clock(true);
		this.renderer = new THREE.WebGLRenderer({ alpha: true });

		this.attach();
		this.setSize();
		this.cameraSetup();
		this.loadTextures();
		this.createMesh();

		this.render();
		this.initResize();
	}

	initResize()
	{
		setTimeout(() =>
		{
			this.setSize();
		}
		, 60);

		window.addEventListener('resize', (e) =>
		{
			this.setSize();
		}
		);
	}

	start()
	{
		this.nextSlide(true);
	}

	stop()
	{
		gsap.killTweensOf(this.mat.uniforms);
	}

	mount()
	{
		this.attach();
	}

	unmount()
	{
		this.stop();
		this.remove();
	}

	setSize()
	{
		let w = this.el.offsetWidth;
		let h = this.el.offsetHeight;

		let rw, rh;
		
		if (w /h > this.srcSize.w / this.srcSize.h)
		{
			rw = w;
			rh = w * this.srcSize.h / this.srcSize.w;
		}
		else
		{
			rh = h;
			rw = h * this.srcSize.w / this.srcSize.h;
		}

		if (this.renderer)
		{
			this.renderer.setPixelRatio(window.devicePixelRatio);
			this.renderer.setSize(rw, rh);
		}
		
		if (this.camera)
		{
			this.camera.aspect = w / h;
  			this.camera.updateProjectionMatrix();
		}
	}

	attach()
	{
		this.container.appendChild(this.renderer.domElement);
	}

	remove()
	{
		this.container.removeChild(this.renderer.domElement);
	}

	bindAll()
	{
		['render', 'nextSlide'].forEach(fn => this[fn] = this[fn].bind(this));
	}

	cameraSetup()
	{
		let w = this.el.offsetWidth;
		let h = this.el.offsetHeight;

		this.camera = new THREE.OrthographicCamera(-w/2, w/2, h/2, -h/2, 1, 1000);

		this.camera.lookAt(this.scene.position);
		this.camera.position.z = 1;
	}

	loadTextures()
	{
		const manager = new THREE.LoadingManager();

		manager.onLoad = () =>
		{
			this.start();
		}

		const loader = new THREE.TextureLoader(manager);
		loader.crossOrigin = '';

		this.textures = [];

		for (let i = 0; i < this.imgURLs.length; i++)
		{
			const texture = loader.load(this.imgURLs[i] + '?v=' + Date.now(), this.render);
			texture.minFilter = THREE.LinearFilter;

			this.textures.push(texture);
		}

		this.data.total = this.textures.length;

		this.disp = loader.load(this.map_img, this.render);

		this.disp.magFilter = this.disp.minFilter = THREE.LinearFilter;
		this.disp.wrapS = this.disp.wrapT = THREE.RepeatWrapping;
	}

	createMesh()
	{
		let gw = this.el.offsetWidth;
		let gh = this.el.offsetHeight;

		this.mat = new THREE.ShaderMaterial(
		{
			uniforms:
			{
				dispPower: { type: 'f', value: 0.0 },
				intensity: { type: 'f', value: 1.0 },
				texture1: { type: 't', value: null },
				texture2: { type: 't', value: this.textures[0] },
				disp: { type: 't', value: this.disp },
				scale1: { type: 'f', value: 1.0 },
				scale2: { type: 'f', value: 1.0 },
			},
			transparent: true,
			vertexShader: this.vertexShader,
			fragmentShader: this.fragmentShader
		}
		);

		const geometry = new THREE.PlaneBufferGeometry(gw, gh, 1);
		const mesh = new THREE.Mesh(geometry, this.mat);

		this.scene.add(mesh);
	}

	transitionNext()
	{
		let is_first = this.mat.uniforms.texture1.value === null;

		let t1 = this.interval;
		let t2 = this.transitionDuration;
		let scale1 = 1 + (this.scaleEnd - 1) * (t1 / (t1 + t2));

		this.mat.uniforms.dispPower.value = 0.0;
		this.mat.uniforms.scale1.value = scale1;
		this.mat.uniforms.scale2.value = 1;

		gsap.to(this.mat.uniforms.dispPower, t2 * (is_first?1:1),
		{
			value: 1,
			ease: is_first?Cubic.easeOut:Cubic.easeInOut,
			onComplete: () =>
			{
				this.state.animating = false;
			}
		}
		);

		gsap.to(this.mat.uniforms.scale1, t2,
		{
			value: this.scaleEnd,
			ease: Linear.easeNone
		}
		);

		gsap.to(this.mat.uniforms.scale2, t1,
		{
			value: scale1,
			ease: Linear.easeNone,
			onUpdate: () =>
			{
				this.render();
			},
			onComplete: () =>
			{
				this.render.bind(this);
				this.changeTexture();
				this.nextSlide();
			}
		}
		);
	}

	nextSlide(isFirst)
	{
		if (this.state.animating) return;
		this.state.animating = true;

		this.transitionNext();

		this.data.current = this.data.current === this.data.total - 1 ? 0 : this.data.current + 1;
		this.data.next = this.data.current === this.data.total - 1 ? 0 : this.data.current + 1;
	}

	changeTexture()
	{
		this.mat.uniforms.texture1.value = this.textures[this.data.current];
		this.mat.uniforms.texture2.value = this.textures[this.data.next];
	}

	// Render the scene
	render()
	{
		this.renderer.render(this.scene, this.camera);
	}
}