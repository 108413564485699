import React, { useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import classNames from 'classnames/bind';
import $ from 'jquery';

export const ScreenTabs = (props) =>
{
	const location = useLocation();
	const history = useHistory();

	let query = new URLSearchParams(location.search);

	let tabID = query.get("tab");

	const tabs = [];
	let tab_content = null;

	for (let i = 0; i < props.tabs.length; i++)
	{
		let e = props.tabs[i];

		//Use first tab id if query parameter is null
		if (!tabID) tabID = e.key;

		let active = (e.key.toString() === tabID.toString())?1:0;

		let tab =
		(
			<li key={i} className={classNames('tab', props.n)} data-index={i} data-key={e.key} data-active={active}>
				<div className='link' onClick={() => openTab(e.key)}>
					<p className='label'>{e.name}</p>
				</div>
			</li>
		);

		tabs[i] = tab;

		if (active) tab_content = e.content;
	}

	let bar_left = null;
	let bar_right = null;
	let bar_after = null;

	if (props['bar-left']) bar_left = props['bar-left'];
	if (props['bar-right']) bar_right = props['bar-right'];
	if (props['bar-after']) bar_after = props['bar-after'];

	const openTab = (id) =>
	{
		query.set('tab', id);
		let url = location.pathname + '?' + query.toString();
		history.replace(url);
	}

	useEffect(() =>
	{
		$('.screen').scrollTop(0);
		//$('.screen-inner').scrollTop(0);

		updateTip();

		$(window).on('resize', updateTip);

		function updateTip()
		{
			if ($('.tabs-bar').hasClass('has-tip-up'))
			{
				var $tab = $('.tabs-bar li.tab[data-active=1]');

				var tw = $tab.width();
				var tx = $tab.offset().left;

				$('.tab-content .card-page').each(function()
				{
					var tx2 = tx + tw/2 - $(this).offset().left;
					$(this).find('.card-tip').css('left', tx2);
				}
				);
			}
		}

		return () => $(window).off('resize');
	}
	, [tabID]);

	return (
		<>
			<div className={classNames('tabs-bar', props.n, props.className)} data-name={props.n}>
				{bar_left}
				<ul className='tabs'>
					{tabs}
				</ul>
				{bar_right}
			</div>
			{bar_after}
			<div key={tabID} className={classNames('tab-content', props.n, props.className)} data-name={props.n} data-key={tabID}>
				{tab_content}
			</div>
		</>
	);
}

