import React, { useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AppContext } from '../../App';
import { ReactComponent as Map } from '../../svg/japanmap.svg';
import $ from 'jquery';

export const JapanMap = (props) =>
{
	const {showrooms, showroomAreas, showroomPrefectures} = React.useContext(AppContext);

	const areas = showroomAreas.current;
	const prefectures = showroomPrefectures.current;

	const areaID = props['area-id'];

	const iid = useRef();

	const getPrefectureFromName = (name_en) =>
	{
		return prefectures.find(e => e.en === name_en);
	}

	const zoomIn = (area_id) =>
	{
		window.clearTimeout(iid.current);

		$('#map').attr('data-zoom', 1);
		$('#map').attr('data-zoom-id', area_id);
	}

	const zoomOut = () =>
	{
		window.clearTimeout(iid.current);

		$('#map').attr('data-zoom', 0);	

		iid.current = window.setTimeout(() => 
		{
			$('#map').attr('data-zoom-id', 0);
		}
		, 600);
	}

	useEffect(() =>
	{
		if (areaID == 0)
			zoomOut();
		else
			zoomIn(areaID);
	}
	);

	useEffect(() =>
	{
		$('svg g#japan-map path[data-name]').on('click', (e) =>
		{
			let name_en = $(e.target).attr('data-name');
			
			if (!name_en) return;
			
			let pe = getPrefectureFromName(name_en);
			let zoom = parseInt($('#map').attr('data-zoom'));

			//Zoomed In
			if (zoom == 0)
			{
				props.setArea(pe.area_id);
			}
			else
			//Prefecture
			{
				props.setArea(pe.area_id, pe.id);
			}
		}
		);
	}
	, []);

	const prefecture_list = [];

	prefectures.forEach((e, i) => 
	{
		prefecture_list.push((
			<li
				key = {i}
				data-id={e.id}
				data-name={e.en}
				data-area={e.area_id}
				data-active={e.num_showrooms > 0?1:0}
			>
				<a onClick={() => props.setArea(e.area_id, e.id)}>{e.name}</a>
			</li>
		));
	}
	);

	return (
		<div id='map' className='map' data-zoom='0'  data-zoom-id='0'>
			<div className='map-header'>
				<div className='box message'>
					<p className='message'>
						お住いの地域をお選びください
					</p>
				</div>
			</div>
			<div className='map-wrapper'>
				<div className='map-container'>
					<div className='map-inner'>
						<ul className='name-list'>
							{prefecture_list}
						</ul>
						<Map/>
					</div>
				</div>
				<div className='corner br'>
					<button id='btn-zoomout' className='btn zoom-out' onClick={() => props.setArea(0, 0)}>
						<div className='label'>
							<p className='icon zoom-out'/>
							<p className='text'>全国</p>
						</div>
					</button>
				</div>
			</div>
		</div>
	);
}

export default JapanMap;