//機種別料金表

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MENU } from '../_Params';
import { Screen, Font, ColumnBar } from '../_sections/_UI_Common';
import { ItemPriceCard } from '../_sections/_UI_Items';
import { ScreenTabs } from '../_sections/_UI_Tabs';
import { getDocumentTitle, getTabNameFromKey, getItemsBySeries, parseNotes } from '../_utils/_Utils';
import { useScreenConfig, useScreenTitle, useURLHistory } from '../_utils/_Hooks';
import $ from 'jquery';
import settings from '../../json/settings.json';

export const ScreenPriceList = (props) =>
{
	const location = useLocation();

	const {screenConfig} = useScreenConfig();
	const {setScreenTitle} = useScreenTitle();
	const {setBack, saveQuery} = useURLHistory();

	let query = new URLSearchParams(location.search);
	let tabID = query.get("tab");

	const getItems = (series) =>
	{
		const params = getItemsBySeries(series, []);

		const items = [];

		for (let i = 0; i < params.length; i++)
		{
			let p = params[i];

			if (!p['is-pricelist']) continue;

			let notes = {};

			notes.price = [];

			if (p.notes)
			{
				notes.price = notes.price.concat(p.notes.split(/\n\r?/));
			}

			let item =
			(
				<li key={i} data-index={i}>
					<ItemPriceCard data={p} type={series} notes={notes}/>
				</li>
			);

			items.push(item);
		}

		return (
			<ul className={'card-list products animate'} data-type={series} data-style={'list'}>
				{items}
			</ul>
		);
	}

	const items = [];
	
	//nano
	items[0] = getItems('nano');
	//premium
	items[1] = getItems('premium');

	const tabs = [];

	tabs[0] =
	{
		name: 'ナノシリーズ',
		key: 'nano',
		content: (
			<div className='container'>
				{items[0]}
			</div>
		)
	}

	tabs[1] =
	{
		name: 'プレミアムシリーズ',
		key: 'premium',
		content: (
			<div className='container'>
				{items[1]}
			</div>
		)
	}

	const labels =
	[
		{
			name: '機種',
			key: 'image'
		},
		{
			name: '機能', 
			key: 'features'
		},
		{
			name: '料金',
			key: 'price'
		}
	];

	const bar_after = 
	(
		<ColumnBar c='price-list' labels={labels}/>
	); 

	useEffect(() =>
	{
		const tabName = getTabNameFromKey(tabID, tabs);

		document.title = getDocumentTitle(MENU.PRICELIST, tabName);
		setScreenTitle(MENU.PRICELIST, tabName);
	}
	, [tabID]);

	useEffect(() =>
	{
		screenConfig();
		setBack('/');
		saveQuery();
	}
	, []);

	const onComplete = () =>
	{
		$('ul.card-list >li').addClass('is-in');
	}

	return (
		<Screen id='price-list' onComplete={onComplete} {...props}>
			<ScreenTabs n={'price-list'} tabs={tabs} bar-after={bar_after}/>
		</Screen>
	);
}

export default ScreenPriceList;