import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { MENU } from '../_Params';
import { AppContext } from '../../App';
import { getDocumentTitle, getTabNameFromKey } from '../_utils/_Utils';
import { Screen } from '../_sections/_UI_Common';
import { ItemShowroomCard } from '../_sections/_UI_Items';
import { ScreenTabs } from '../_sections/_UI_Tabs';
import { useScreenConfig, useScreenTitle, useURLHistory } from '../_utils/_Hooks';
import $ from 'jquery';

export const ScreenShowroomArea = (props) =>
{
	const location = useLocation();
	const history = useHistory();

	let query = new URLSearchParams(location.search);

	const areaID = query.get("area-id");
	let prefectureID = query.get("tab");

	const {screenConfig} = useScreenConfig();
	const {setScreenTitle} = useScreenTitle();
	const {setBack, saveQuery} = useURLHistory();

	const {showroomAreas, showroomPrefectures} = React.useContext(AppContext);

	const areas = showroomAreas.current;
	const prefectures = showroomPrefectures.current;

	const getShowroomItems = (showrooms) =>
	{
		const items = [];

		for (let i = 0; i < showrooms.length; i++)
		{
			let p = showrooms[i];

			let item =
			(
				<li key={prefectureID + '-' +  i} data-index={i} className='showroom-item'>
					<ItemShowroomCard data={p}/>
				</li>
			);

			items.push(item);
		}

		return (
			<ul className={'card-list showrooms animate'} data-style='grid' data-cols={2}>
				{items}
			</ul>
		);
	}

	const tabs = [];

	if (areas[areaID - 1])
	{
		let areaPrefs = areas[areaID - 1].prefectures;

		if (!prefectureID) prefectureID = areaPrefs[0].id;

		areaPrefs.forEach((ae, ai) =>
		{
			if (ae.num_showrooms > 0)
			{
				let showrooms = prefectures[prefectureID - 1].showrooms;

				tabs.push(
				{
					name: ae.name,
					key: ae.id,
					content: (
						<div className='container'>
							{getShowroomItems(showrooms)}
						</div>
					)
				}
				);
			}
		}
		);
	}

	useEffect(() =>
	{
		if (!areaID) history.push('/showroom');

		screenConfig();

		const e = areas[areaID - 1];
		const cp = prefectures[prefectureID - 1];

		const areaName = (e)?e.name:'';
		const prefName = (cp)?cp.name:'';
		
		document.title = getDocumentTitle(MENU.SHOWROOM, areaName, prefName);
		setScreenTitle(MENU.SHOWROOM, areaName, prefName);	

		setBack('/showroom');
		saveQuery();
	}
	, [areaID, prefectureID]);

	const onComplete = () =>
	{
		$('ul.card-list >li').addClass('is-in');
	}

	return (
		<Screen id='showroom-area' onComplete={onComplete} {...props}>
			<ScreenTabs n={'prefectures'} tabs={tabs} className={tabs.length >= 6?'narrow':''}/>
		</Screen>
	);
}

export default ScreenShowroomArea;