import React, { Component, useEffect, useContext, useRef } from 'react';
import { motion } from "framer-motion"
import { Font } from '../_sections/_UI_Common';
import { getItemsByID } from '../_utils/_Utils';
import { AppMainContext } from '../../App';
import Water from '../../js/_classes/Water';
import settings from '../../json/settings.json';
import { ReactComponent as Logo } from '../../svg/logo_waterstand_wmask.svg';
import $ from 'jquery'
import Slider from "react-slick";

export const ScreenLock = (props) =>
{	
	const {lockControl} = React.useContext(AppMainContext);

	let slider = null;
	let water = null;

	const iid = useRef();

	const makeItems = () =>
	{
		const imgs = getItemsByID(settings.lock.slideItems, ['img', 'name', 'name-en']);

		const ul = [];

		for (let i = 0; i < imgs.length; i++)
		{
			let e = imgs[i];

			ul[i] = (
				<div key={i} className='slide-item' data-index={i}>
					<figure>
						<img src={e.img} alt={e.name}/>
					</figure>
					<p className='name jp'>{e.name}</p>
					<p className='name en'>
						<span className='font en1'>{e['name-en']}</span>
					</p>
				</div>
			);
		}

		const params =
		{
			infinite: true,
			speed: settings.lock.slideSpeed * 1000,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: settings.lock.slideInterval * 1000,
			arrows: false,
			dots: false
		};
 
		return (
			<div className='items' id='lock-items'>
				<Slider ref={c => (slider = c)} {...params}>{ul}</Slider>
			</div>
		);
	}

	const initWater = () =>
	{
		const sliderParams = 
		{
			el: '#lock-screen .container',
			container: '#lock-screen .bg',
			srcSize: { w:1200, h:1600 },
			speedX: 0.001,
			speedY: 0.002,
			intensity: 0.25,
			tex_img: './img/top/bg_water_tex.jpg',
			map_img: './img/top/bg_water_displacement.jpg',
			imgURLs: props.imgs
		};

		water = new Water(sliderParams);
	}

	const unlock = () =>
	{
		lockControl.unlock();
	}

	useEffect(() =>
	{
		initWater();

		$('.logo.waterstand').addClass('is-in');

		iid.current = window.setTimeout(() =>
		{
			$('.items-wrap .items').addClass('active');
			$('.logo.waterstand').addClass('up');
			if (slider) slider.slickPlay();
		}
		, 2500);

		return () =>
		{
			window.clearTimeout(iid.current);
			if (water) water.unmount();
		}
	}
	);

	const items = makeItems();

	const transition = { type:'tween', ease:'easeOut', duration: 0.5 };

	return (
		<motion.div
			className='lock-screen'
			id = 'lock-screen'
			initial={{opacity: 0, transition: transition}} 
			animate={{opacity: 1, transition: transition}}
			exit={{opacity: 0, transition: transition}} 
			onClick={unlock}
		>
			<div className='lock-logo'>
				<div className='logo waterstand'>
					<Logo/>
				</div>
			</div>
			<div className='container'>
				<div className='items-wrap'>
					{items}
				</div>
			</div>
			<div className='lock-footer'>
				<div className='inner'>
					<p className='icon finger'/>
					<p className='message'><Font n="jp1">画面をタップしてください</Font></p>
				</div>
			</div>
			<div className='bg' id='water-bg'></div>
		</motion.div>
	);
}

export default ScreenLock;