import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation, useHistory } from 'react-router-dom';
import { AnimatePresence } from "framer-motion";
import { isBrowser, isMobile } from "react-device-detect";
import IdleTimer, { useIdleTimer } from 'react-idle-timer/dist/modern';
import { Header, FooterHelpBtn } from './jsx/_sections/_UI_Common';
import { Modal, ModalMenu, VideoModal } from './jsx/_sections/_Modal';
import ScrollTopOnRoute from './jsx/_sections/_ScrollTopOnRoute';
import ScreenLoading from './jsx/_screens/_Screen_Loading';
import ScreenTop from './jsx/_screens/_Screen_Top';
import ScreenLock from './jsx/_screens/_Screen_Lock';
import ScreenCatalog from './jsx/_screens/_Screen_Catalog';
import ScreenItemDetail from './jsx/_screens/_Screen_ItemDetail';
import ScreenHelpSetup from './jsx/_screens/_Screen_HelpSetup';
import ScreenHelpOrder from './jsx/_screens/_Screen_HelpOrder';
import ScreenPriceList from './jsx/_screens/_Screen_PriceList';
import ScreenShowroom from './jsx/_screens/_Screen_Showroom';
import ScreenShowroomArea from './jsx/_screens/_Screen_ShowroomArea';
import { parseAreas, parseShowroomData } from './jsx/_utils/_Utils_Showroom';
import { usePageDirection } from './jsx/_utils/_Hooks';
import './app.sass';
import settings from './json/settings.json';
import assets from './json/assets_manifest.json';
import stands from './json/params_stands.json';
import prefectures from './json/params_prefectures.json';
import $ from 'jquery';
import createjs from 'preload-js';
import gtm from 'react-gtm-module';

export const AppContext = React.createContext();
export const AppMainContext = React.createContext();

const App = () => 
{
	const gtmArgs =
	{
		gtmId: 'GTM-W6K65RM'
	}

	gtm.initialize(gtmArgs)

	const hasPreload = true;

	const standDataJSON = '';
	const showroomDataJSON = '/json/params_showrooms.json';

	const areas = parseAreas();

	const showrooms = useRef([]);
	const showroomAreas = useRef(areas);
	const showroomPrefectures = useRef(prefectures);

	const assetsManifest = assets;
	const standsHTMLManifest = [];

	const [isLoaded, setIsLoaded] = useState(0);
	const [hasLoader, setHasLoader] = useState(true);

	const startLoad = () =>
	{
		promiseShowroomJSON().then((data) =>
		{
			showrooms.current = data;

			const r = parseShowroomData(data);

			showroomAreas.current = r.areas;
			showroomPrefectures.current = r.prefectures;

			addAssetsToManifest();

			return promiseAssets();
		}
		).then((data) =>
		{
			return promiseStandsHTMLs();
		}
		).then((data) =>
		{
			return promiseFonts();
		}
		).then((data) =>
		{
			if (hasPreload) endLoad();
		}
		).catch((error) =>
		{
			console.log('[LOAD ERROR]');
		}
		);
	}

	const endLoad = () =>
	{
		setIsLoaded(1);

		setTimeout(() =>
		{
			setHasLoader(false);
		}
		, 1550);
	}

	const addAssetsToManifest = () =>
	{
		//Images
		if (settings && settings.top)
		{
			if (settings.top.kv && settings.top.kv.imgs)
			{
				settings.top.kv.imgs.forEach((img) =>
				{
					if (assetsManifest.indexOf(img) < 0) assetsManifest.push(img);
				}
				);
			}

			if (settings.top.videos)
			{
				settings.top.videos.forEach((e) =>
				{
					if (assetsManifest.indexOf(e.img) < 0) assetsManifest.push(e.img);
				}
				);
			}
		}

		stands.forEach((e) =>
		{
			let img = '/img/products/' + e['img-id'] + '.png'
			if (assetsManifest.indexOf(img) < 0) assetsManifest.push(img);

			let img_s = '/img/products/' + e['img-id'] + '_s.png'
			if (assetsManifest.indexOf(img) < 0) assetsManifest.push(img_s);
		}
		);

		/*
		if (showrooms.current)
		{
			showrooms.current.forEach((e) => 
			{
				let img = '/img/' + e['main-img'];
				if (assetsManifest.indexOf(img) < 0) assetsManifest.push(img);
			}
			);
		}
		*/

		//HTMLs
		stands.forEach((e, index) =>
		{
			let html = '/html/' + e['features-html'];
			if (standsHTMLManifest.indexOf(html) < 0) standsHTMLManifest.push({ id:index, src:html });
		}
		);
	}

	const promiseFonts = () =>
	{
		return new Promise((resolve, reject) =>
		{
			const FONTPLUS = window['FONTPLUS'];

			let chars = '';

			//chars = Font.chars.jp1;
			chars += 'カタログを見るはじめてのウォースンドかんた工事で使えオライ相談す画面ップしくださいお申込み機種別料金表';

			chars = parseCharsInJSON(settings, ['top', 'videos', 'title'], chars);

			//console.log('[Chars]', chars);

			const fontdata = 
			{
				'fontname': 'TsukuARdGothicStd-B',
				'nickname': 'tsukuargo-b',
				'text': chars
			}

			let fonts_loaded = false;

			if (FONTPLUS)
			{
				FONTPLUS.load([fontdata], () =>
				{
					console.log('[FONTS LOADED]');
					fonts_loaded = true;
					resolve();
				}
				);

				setTimeout(() =>
				{
					if (!fonts_loaded)
					{
						console.log('[FONTS LOAD TIMEOUT]');
						fonts_loaded = true;
						resolve();
					}
				}
				, 1000);
			}
			else
			{
				resolve();
			}
		}
		);
	}

	const promiseShowroomJSON = () =>
	{	
		return new Promise((resolve, reject) =>
		{
			fetch(showroomDataJSON)
			.then((response) => response.json())
			.then((data) =>
			{
				console.log('[Showroom DATA LOADED]');
				resolve(data);
			}
			)
			.catch((error) =>
			{
				console.error('[Showroom DATA LOAD ERROR]', error);
				reject(error);
			}
			);
		}
		);
	}

	const promiseAssets = () =>
	{	
		return new Promise((resolve, reject) =>
		{
			const queue = new createjs.LoadQueue(false);

			queue.addEventListener("fileload", (e) =>
			{
				//console.log('fileload', e.item);
			}
			);

			queue.addEventListener("error", (e) =>
			{
				console.log('error', e);
			}
			);

			queue.addEventListener("fileerror", (e) =>
			{
				console.log('fileerror', e.item);
			}
			);

			queue.addEventListener("complete", (e) =>
			{
				console.log('[Assets LOAD COMPLETE] Loaded Items: ' + e.target._numItemsLoaded,);
				resolve();
			}
			);

			queue.loadManifest(assetsManifest);
		}
		);
	}

	const promiseStandsHTMLs = () =>
	{	
		return new Promise((resolve, reject) =>
		{
			const queue = new createjs.LoadQueue(false);

			queue.addEventListener("fileload", (e) =>
			{
				//console.log('fileload', e.result);
				stands[e.item.id]['features-html-data'] = e.result;
			}
			);

			queue.addEventListener("error", (e) =>
			{
				console.log('error', e);
			}
			);

			queue.addEventListener("fileerror", (e) =>
			{
				console.log('fileerror', e.item);
			}
			);

			queue.addEventListener("complete", (e) =>
			{
				console.log('[HTMLs LOAD COMPLETE] Loaded Items: ' + e.target._numItemsLoaded,);
				resolve();
			}
			);

			queue.loadManifest(standsHTMLManifest);
		}
		);
	}

	const parseCharsInJSON = (json, keys, chars) =>
	{
		chars = chars || '';

		const parse = (data, keys1) =>
		{
			if (Array.isArray(data))
			{
				for (let i = 0; i < data.length; i++)
				{
					let keys2 = keys1.concat();
					parse(data[i], keys2);
				}
			}
			else
			{
				if (keys1.length === 0)
				{
					addChar(data.toString());
				}
				else
				{
					let key = keys1.shift();
					parse(data[key], keys1);
				}
			}
		}

		const addChar = (str) =>
		{
			for (let i = 0; i < str.length; i++)
			{
				let char = str.charAt(i);
				if (chars.indexOf(char) < 0) chars += char;
			}
		}

		parse(json, keys);

		return chars;
	}

	useEffect(() =>
	{
		startLoad();
	}
	, []);

	return (
		<AppContext.Provider value={{showrooms, showroomAreas, showroomPrefectures}}>
			{(hasPreload && hasLoader) && <ScreenLoading loaded={isLoaded === 1?1:0}/>}
			<Router>
				<AppMain/>
			</Router>
		</AppContext.Provider>
	);
}

const AppMain = (props) =>
{
	const [isLocked, setIsLocked] = useState(0);

	const location = useLocation();
	const history = useHistory();

	const [direction, depth] = usePageDirection();

	//Header
	const [headerTitle, setHeaderTitle] = useState('');

	const headerControl = {};

	const hasHeader = depth > 0;

	headerControl.setTitle = (title) =>
	{
		setHeaderTitle(title);
	}

	//Footer
	const [hasHelp, setHasHelp] = useState(0);

	const footerControl = {};
	
	footerControl.hasHelp = (bool) =>
	{
		setHasHelp(bool?1:0)
	}

	//Modal
	const [modalID, setModalID] = useState('');
	const [modalOpened, setModalOpened] = useState(false);

	const modalControl = {};

	modalControl.open = (id) =>
	{
		setModalID(id);
		setModalOpened(true);
	}

	modalControl.close = () =>
	{
		setModalOpened(false);
	}

	//Lock Screen / Idle Timer
	let idleTimer = null

	const lockControl = {};

	lockControl.lock = () =>
	{
		setIsLocked(1);
	}

	lockControl.unlock = () =>
	{
		history.push("/");
		setIsLocked(0);
	}

	const handleOnAction = () =>
	{
		console.log('Lock Disabled on Action');
	}

	const handleOnActive = () =>
	{
		console.log('Lock Disabled on Active');
		//if (isLocked === 1) setIsLocked(0);
	}

	const handleOnIdle = () =>
	{
		if (settings.lock.idleTime <= 0 || isMobile) return;
		console.log('Lock Enabled');
		setIsLocked(1);
	}

	//Video
	const [videoID, setVideoID] = useState('');
	const [videoModalOpened, setVideoModalOpened] = useState(false);

	const videoControl = {};

	videoControl.open = (video_id) =>
	{
		idleTimer.pause();
		setVideoID(video_id);
		setVideoModalOpened(true);
	}

	videoControl.close = () =>
	{
		setVideoModalOpened(false);
		idleTimer.start();
	}
	
	useEffect(() =>
	{
	}
	);

	return (
		<AppMainContext.Provider value={{direction, headerControl, footerControl, modalControl, lockControl, videoControl}}>
			<IdleTimer
				ref={ ref => {idleTimer = ref} }
				timeout={ 1000 * settings.lock.idleTime }
				onActive={ handleOnActive }
				onIdle={ handleOnIdle }
				onAction={ handleOnAction }
				debounce={ 250 }
			/>
			<ScrollTopOnRoute/>
			{isLocked === 1 &&
				<AnimatePresence>
					<ScreenLock/>
				</AnimatePresence>
			}
			<main id="main" data-is-locked={isLocked?1:0}>
				<>
					<VideoModal
						is-open={videoModalOpened}
						video-id={videoID}
						onClose={() => videoControl.close()}
					/>
					<Modal id={modalID} is-open={modalOpened}>
						{modalID === 'menu' && <ModalMenu />}
					</Modal>
					<AnimatePresence>
						{hasHeader && <Header title={headerTitle}/>}
					</AnimatePresence>
					<AnimatePresence>
						{hasHelp && <FooterHelpBtn/>}
					</AnimatePresence>
					<AnimatePresence exitBeforeEnter initial={false}>
						<Switch location={location} key={location.pathname}>
							<Route path='/catalog/item:item_id' component={ScreenItemDetail}/>
							<Route exact path='/catalog' component={ScreenCatalog}/>
							<Route exact path='/catalog/item'>
								<Redirect to="/catalog" />
							</Route>
							<Route path='/setup' component={ScreenHelpSetup}/>
							<Route path='/order' component={ScreenHelpOrder}/>
							<Route path='/price-list' component={ScreenPriceList}/>
							<Route path='/showroom/area' component={ScreenShowroomArea}/>
							<Route exact path='/showroom'  component={ScreenShowroom}/>
							<Route exact path='/' component={ScreenTop}/>
						</Switch>
					</AnimatePresence>
				</>
			</main>
		</AppMainContext.Provider>
	);
}

export default App;
