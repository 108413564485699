//設置方法

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MENU } from '../_Params';
import { getDocumentTitle, getTabNameFromKey } from '../_utils/_Utils';
import { Screen, SubSectionWNum, BlockVideoTxt, ReturnTopBtn } from '../_sections/_UI_Common';
import { ScreenTabs } from '../_sections/_UI_Tabs';
import { PhoneNum } from '../_sections/_UI_Parts';
import { useScreenConfig, useScreenTitle, useURLHistory } from '../_utils/_Hooks';
import Slider from "react-slick";
import $ from 'jquery';

const ScreenHelpSetup = (props) =>
{
	const location = useLocation();

	const {screenConfig} = useScreenConfig();
	const {setScreenTitle} = useScreenTitle();
	const {setBack, saveQuery, clearQuery} = useURLHistory();

	let query = new URLSearchParams(location.search);
	let tabID = query.get("tab");

	const Item = (props) =>
	{
		let img = '/img/howto/howto_secchi_0' + (props.index + 1) + '.png';

		return (
			<div key={props.index} className='slide-item' data-index={props.index}>
				<div className='title'>
					{props.title}
				</div>
				<div className='text'>
					{props.children}
				</div>
				<figure>
					<img src={img} alt={props.title}/>
				</figure>
			</div>
		);
	}

	const makeItems = () =>
	{
		let slider;

		const ul = [];

		ul[0] = (
			<Item index={0} key={0} title={'（Ａ）調理台置き・通常配線'}>
				調理代の上にウォータースタンドを設置し、水栓に分岐部品を取り付けホースをはわせてモールでカバーします。
			</Item>
		);

		ul[1] = (
			<Item index={1} key={1} title={'（Ｂ）収納台置き・通常配線'}>
				キッチン台の背面などに位置する収納台にウォータースタンドを設置し、水栓に分岐部品を取り付け、壁側の床をつたってホースをはわせてモールでカバーします。
			</Item>
		);

		ul[2] = (
			<Item index={2} key={2} title={'（Ｃ）収納台置き・床上配線'}>
				キッチン台と離れたところに背面に位置する台にウォータースタンドを置き、水栓に分岐部品を取り付け、床をつたってホースをはわせて、床用モールでカバーします。
			</Item>
		);

		ul[3] = (
			<Item index={3} key={3} title={'（Ｄ）調理台置き・アンダーシンク配線'}>
				キッチン台の上にウォータースタンドを置き、アンダーシンクに分岐部品を取り付け、床上にホースをはわせてモールでカバーします。
			</Item>
		);

		const params =
		{
			className: 'cards',
			infinite: true,
			speed: 400,
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			arrows: false
		};

		const next = () =>
		{
			if (slider) slider.slickNext();
		}

		const prev = () =>
		{
			if (slider) slider.slickPrev();
		}
 
		return (
			<div className='slider-wrapper items' id='setup-charts'>
				<Slider ref={c => (slider = c)} {...params}>{ul}</Slider>
				<div className='slider-btns'>
					<button className='slider-btn next' onClick={next}>
						<p className='icon angle-right'/>
					</button>
					<button className='slider-btn prev' onClick={prev}>
						<p className='icon angle-left'/>
					</button>
				</div>
			</div>
		);
	}

	const tabs = [];

	tabs[0] = 
	{
		name: '設置方法',
		key: 'setup',
		content: (
			<div className="container">
				<div className="card-page w-title setup">
					<div className="card-tip"></div>
					<div className="card-title">
						<h2>設置方法</h2>
					</div>
					<section className='lead'>
						<div className="container">
							<p>
								一般のご家庭の場合、ウォータースタンドの設置場所は、料理をする際にお水を使いやすい<strong>キッチン回り</strong>がおすすめです。
								以下を参考にして下さい。
							</p>
						</div>
					</section>
					<section className='setups mt40'>
						<SubSectionWNum num={1} title={'水栓からの距離について'}>
							<BlockVideoTxt video-id='5HTZDVcDFjs'>
								<p>
									水栓に分岐部品をとりつけて、細いホースでウォータースタンドにつなぎます。
									この長さは、プレミアムシリーズで ～５ｍくらいまで、ナノシリーズなら １０ｍ以上離れた場所でも設置できます。
								</p>
								<p className='note'>
									※水圧により差が生じる可能性があります。
								</p>
							</BlockVideoTxt>
						</SubSectionWNum>
						<SubSectionWNum num={2} title={'設置場所について'}>
							<p>
								ウォータースタンドの設置方法には色々なパターンがあります。
								下記（Ａ）～（Ｄ）は代表的な例です。実際には、お部屋のレイアウトに応じてご提案させていただきます。<br/>
								食洗機との併用も可能です。
							</p>
						</SubSectionWNum>
						<section className='sub slider'>
							{makeItems()}
						</section>
						<SubSectionWNum num={3} title={'水栓からの分岐'}>
							<BlockVideoTxt video-id='rLneJ_Sofqk'>
								<p>
									お客様の設置環境に合わせて分岐水栓を取付けます。食洗機との併用もできるのでご相談下さい。
								</p>
								<p className='note'>
									※ 分岐部品の料金はかかりません。分岐金具は取り外して原状復帰できます。
								</p>
							</BlockVideoTxt>
							<div className='block tubes'>
								<div className='block-title'>
									<h4>チューブの配線処理について</h4>
								</div>
								<ul className='boxes'>
									<li className='box' key='box-1'>
										<h5>ナノトラップフィルターの場合</h5>
										<img src='/img/howto/howto_tube_01.png' alt='ナノトラップフィルターの場合'/>
										<ul className='steps'>
											<li>１．水道上水にチューブを専用分岐栓で接続</li>
											<li>２．100ｖ電源コード（メイトを除く）</li>
										</ul>
									</li>
									<li className='box' key='box-2'>
										<h5>ＲＯフィルター（逆浸透膜）の場合</h5>
										<img src='/img/howto/howto_tube_02.png' alt='ＲＯフィルター（逆浸透膜）の場合'/>
										<ul className='steps'>
											<li>１．水道上水にチューブを専用分岐栓で接続</li>
											<li>２．廃棄水を下水にチューブにて接続</li>
											<li>３． 100ｖ電源コード（ネオス２を除く）</li>
										</ul>
									</li>
								</ul>
							</div>
						</SubSectionWNum>
					</section>
					<section className='link'>
						<div className="container">
							<ReturnTopBtn/>
						</div>
					</section>
				</div>
			</div>
		)
	}

	tabs[1] = 
	{
		name: 'サポートサービス',
		key: 'support',
		content: (
			<div className="container">
				<div className="card-page w-title setup">
					<div className="card-tip"></div>
					<div className="card-title">
						<h2>サポートサービス</h2>
					</div>
					<section className='lead'>
						<div className="container">
							<p>
								ウォータースタンドは<strong>水道直結</strong>のウォーターサーバーです。ご自宅の水道水が複数のフィルターで浄水され、より安心安全で美味しいお水に生まれ変わります。原料は水道水だからかかる費用は<strong>サーバーのレンタル料金・水道代・電気代</strong>だけです。
							</p>
							<p>
								製品の品質を恒常的に担保するためレンタル制を採用し、製品のケアに関わり続けます。毎月のレンタル料金には、以下の各種サービスが付帯します。
							</p>
						</div>
					</section>
					<section className='services mt40'>
						<SubSectionWNum num={1} title={'初期設置作業について'}>
							<p>
								ウォータースタンドはご自宅の水道管に分岐金具を設置し、そこからチューブで水道水を引き込み、ウォータースタンド本体につなぎます。原状復帰可能な設置方法なので、賃貸物件にお住まいの方でも問題なくお使いいただけます。
							</p>
							<p>
								無料訪問診断にて設置場所の状況を確認したうえで、キッチンの動線やお客様の生活スタイルに沿った設置方法をご提案いたします。設置後の使用イメージが明確になり、ご納得いただいたら実際の設置に進みます。
							</p>
							<br/>
							<p>
								設置の際には専門スタッフがご自宅にお伺いし、作業を行います。サーバーを設置するだけではなく、水が通るチューブや電源コードなどのケーブル類で美観を損なわないよう、モールで隠すなどの作業も行います。環境により異なりますが、設置作業にかかる時間は平均で1時間程度です。設置作業後はサーバー本体の調整を行い、使用方法の説明を行います。機種により造水・保冷・保温に必要な時間は異なりますが、設置した当日からお使いいただく事が可能です。
							</p>
							<p>
								設置の作業費用や金具の費用は、毎月のレンタル料金に含まれております。
							</p>
							<p className='note'>
								※代理店対応エリアの一部においては、初期設置費用が別途有料となる場合がございます。
							</p>
						</SubSectionWNum>
						<SubSectionWNum num={2} title={'フィルター交換・定期メンテナンスについて'}>
							<p>
								ウォータースタンドは長期間の使用でも浄水性能が落ちないよう、複数のフィルターを組み合わせています。その中で、最も寿命が短いフィルターが6ヶ月です。
							</p>
							<p>
								6ヶ月毎のフィルター交換は、専任スタッフがお客様のご自宅に伺って行います。その際、サーバー全体のメンテナンスも行います。水質や、普段目の届かないサーバー内部の様子も確認し、ヌメリやカビなどが発生しないよう、丹念に清掃を行います。
							</p>
							<br/>
							<p>
								メンテナンスの機会にお客様に直接お会いして、ご使用の感想を伺ったり、疑問や質問に答えるといったコミュニケーションを大切に考えております。お客様のご意見を直接伺うことで、サービスの改善に生かして参ります。
							</p>
						</SubSectionWNum>
						<SubSectionWNum num={3} title={'お客様サポート'}>
							<p>
								<strong>地域の専任スタッフ</strong>と<strong>年中無休のサービスセンター</strong>が、お客様の快適なお水生活をサポートします。
								使い方が分からない時や万が一の故障の際はお気軽にご相談ください。
							</p>
							<div className='box side-cols phone mt30'>
								<div className='col'>
									<h5>お客様サポート<br className='sp-only'/>電話番号</h5>
								</div>
								<div className='col'>
									<PhoneNum className='c'/>
									<p className='note'>
										（9:00～21:00 ※年中無休）
									</p>
								</div>
							</div>
						</SubSectionWNum>
						<SubSectionWNum num={4} title={'故障修理対応'}>
							<p>
								通常使用による故障時の修理対応は無償で行います。
							</p>
						</SubSectionWNum>
						<SubSectionWNum num={5} title={'経年劣化時の本体交換'}>
							<p>
								長くご愛用いただいているお客様について、経年劣化の場合の本体交換を無償で対応いたしております。交換の目安として、<strong>連続使用6年以上</strong>とさせていただいております。
							</p>
							<br/>
							<p>
								以下の場合は、別途手数料を頂戴いたします。
							</p>
							<div className='box bg-color grey mt10'>
								<ul className='dotted'>
									<li>設置から１年未満の解約の場合、撤去費用11,000円（税込）</li>
									<li>設置から1年未満の機種変更の場合、機種変更手数料11,000円（税込）</li>
								</ul>
							</div>
						</SubSectionWNum>
					</section>
					<section className='link'>
						<div className="container">
							<ReturnTopBtn/>
						</div>
					</section>
				</div>
			</div>
		)
	}

	useEffect(() =>
	{
		document.title = getDocumentTitle(MENU.SETUP, getTabNameFromKey(tabID, tabs));
	}
	, [tabID]);

	useEffect(() =>
	{
		screenConfig();
		setScreenTitle(MENU.SETUP);
		setBack('/');
		saveQuery();
	}
	, []);

	const onComplete = () =>
	{
	}

	return (
		<Screen id='help-setup' onComplete={onComplete} {...props}>
			<ScreenTabs n={'setup'} tabs={tabs} className='has-tip-up'/>
		</Screen>
	);
}

export default ScreenHelpSetup;