import React, { Component, useEffect, useState, useContext, useRef } from 'react';
import { Link, useLocation, useHistory, withRouter } from 'react-router-dom';
import { motion } from "framer-motion"
import Div100vh from 'react-div-100vh';
import { MENU, URLS } from '../_Params';
import { AppMainContext } from '../../App';
import { useScreenTitle, useURLHistory } from '../_utils/_Hooks';
import classNames from 'classnames/bind';
import parse from 'html-react-parser';
import $ from 'jquery';
import { gsap, Cubic } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

export const Screen = (props) =>
{
	let has_header = (props['has-header'] === false)?0:1;

	let has_footer_ui = (props['has-footer-ui'] === false)?0:1;
	has_footer_ui = false;

	const {direction} = useContext(AppMainContext);

	let duration = (props.duration || 0.3) * (direction > 0?1:0.75);

	let motionProps = {};

	let animating = useRef(false);

	let animationStart = () =>
	{
		animating.current = true;
	}

	let animationComplete = () =>
	{
		if (animating.current)
		{
			if (props.onComplete) props.onComplete();
		}

		animating.current = false;
	}

	if (direction != 0)
	{
		motionProps.initial = 'initial';
		motionProps.animate = 'animate';
		motionProps.exit = 'exit';

		motionProps.variants = 
		{
			initial: (direction) =>
			{
				return {
					x: (100 * Number(direction)) + 'vw',
					opacity: 0
				}
			},
			animate: 
			{
				zIndex: 1,
				x: 0,
				opacity: 1,
				transition: { type: 'tween', ease: 'easeOut', duration: duration }
			},
			exit: (direction) =>
			{
				return {
					zIndex: 0,
					x: (-100 * Number(direction)) + 'vw',
					opacity: 0,
					transition: { type: 'tween', ease: 'easeIn', duration: duration }
				}
			}
		}

		motionProps.custom = direction;

		motionProps.onAnimationStart = animationStart;
		motionProps.onAnimationComplete = animationComplete;
	}
	else
	{
		duration = 0;
	}

	useEffect(() => 
	{
		if (!animating.current)
		{
			if (props.onComplete) props.onComplete();
		}
	}
	);

	const className = classNames(
		'screen',
		props.id,
		(has_header)?'w-header':'',
		(has_footer_ui)?'w-footer-ui':'',
	);

	return (
		<motion.div className='screen-wrapper' {...motionProps} >
			<Div100vh className={className} data-name={props.id}>
				<div className='screen-inner'>
					{props.children}
				</div>
			</Div100vh>
		</motion.div>
	);
}

export const Header = (props) =>
{
	const { goBack } = useURLHistory();

	const { modalControl } = useContext(AppMainContext);

	const openMenu = () =>
	{
		modalControl.open('menu');
	}

	const gotoTop = () =>
	{
		$('.screen').scrollTop(0);
	}

	return (
		<motion.header
			initial={{ opacity: 0, y: '-100%' }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: '-100%' }}
			transition={{ type: 'tween', ease: 'easeOut', duration: 0.4 }}
		>
			<div className='inner'>
				<div className='col left'>
					<button id='header-btn-back' className='btn' onClick={goBack}>
						<p className='icon angle-left'/>
					</button>
				</div>
				<div className='col title' onClick={gotoTop}>
					<div className='page-title'>{props.title}</div>
				</div>
				<div className='col right'>
					<button id='btn-menu' className='btn' onClick={openMenu}>
						<p className='icon menu'/>
					</button>
				</div>
			</div>
		</motion.header>
	);
}

export class Font extends Component
{
	static chars = {};

	constructor(props)
	{
		super(props);

		Font.registerChar(props.children, props.n);
	}

	static registerChar(children, key)
	{
		if (Font.chars[key] === undefined) Font.chars[key] = '';
		let str = Font.parseChildren(children);

		for (let i = 0; i < str.length; i++)
		{
			let char = str.charAt(i);
			if (Font.chars[key].indexOf(char) < 0) Font.chars[key] += char;
		}
	}

	static parseChildren(children)
	{
		let str = '';

		for (let i = 0; i < children.length; i++)
		{
			if (typeof children[i] == 'string') str += children[i];
		}

		return str;
	}

	render()
	{
		return (
			<span className={'font ' + this.props.n}>
				{this.props.children}
			</span>
		);
	}
}

export class Btn extends Component
{
	render()
	{
		let btn;

		const props = 
		{
			className: classNames('btn', this.props.c),
			'data-name': this.props.n,
			id: this.props.id
		}

		for (let key in this.props)
		{
			switch(key)
			{
				case 'c':
				case 'n':
				case 'id':
				case 'to':
				break;
				default:
					props[key] = this.props[key];
				break;
			}
		}

		if (this.props.to)
		{
			props.to = this.props.to;

			btn = (
				<Link {...props}>
					<div className='inner'>
						{this.props.children}
					</div>
				</Link>
			);
		}
		else
		{
			btn = (
				<a {...props}>
					<div className='inner'>
						{this.props.children}
					</div>
				</a>
			);
		}

		return btn;
	}
}

export const BtnLabel = (props) =>
{
	let icon = (props.icon)?
	(<p className={'icon ' + props.icon}/>):
	null;

	return (
		<div className='label'>
			{icon}
			<div className='text'>
				{props.children}
			</div>
		</div>
	);
}

export const RectBtn = (props) =>
{
	return (
		<Btn c={classNames('rect', props.c)} n={props.n} id={props.id} to={props.to} href={props.href} onClick={props.onClick}>
			<BtnLabel icon={props.icon}>
				{props.children}
			</BtnLabel>
		</Btn>
	);
}

export const RoundBtn = (props) =>
{
	return (
		<Btn c={classNames('round', props.c)} n={props.n} id={props.id} to={props.to} onClick={props.onClick}>
			<BtnLabel icon={props.icon}>
				{props.children}
			</BtnLabel>
		</Btn>
	);
}

export const RoundCloseBtn = (props) =>
{
	return (
		<RoundBtn c='close border' n='close' icon={'close'} onClick={props.onClick}>
			閉じる
		</RoundBtn>
	);
}

export const ReturnTopBtn = (props) =>
{
	return (
		<RoundBtn c='link border back' n='top' to={'/'}>
			トップへもどる
		</RoundBtn>
	);
}

export const HelpBtn = (props) =>
{
	const returnURL = window.location.origin;
	//const url = URLS.LIVECALL + encodeURI(returnURL);
	const url = URLS.LIVECALL;

	return (
		<Btn c='help' n='livecall' href={url} target='_self'>
			<BtnLabel icon='question'>
				<Font n='jp1'>{MENU.HELP}</Font>
			</BtnLabel>
		</Btn>
	);
}

export const VideoBtn = (props) =>
{
	const {videoControl} = useContext(AppMainContext);

	const video_id = props['video-id'];

	return (
		<Btn c={classNames('video', props.c)}  data-video-id={video_id} onClick={() => videoControl.open(video_id)}>
			<BtnLabel icon='youtube'>
				{props.children}
			</BtnLabel>
		</Btn>
	);
}

export const FooterHelpBtn = (props) =>
{
	return (
		<div className='fixed-footer'>
			<motion.div
				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				exit={{ opacity: 0, y: '100%' }}
				transition={{ type: 'tween', ease: 'easeOut', duration: 0.4 }}
			>
				<HelpBtn/>
			</motion.div>
		</div>
	);
}

export const LockBtn = (props) =>
{
	return (
		<button className={'btn-lock'} {...props}>
			<p className='icon lock'/>
		</button>
	);
}

export const ModeBtns = (props) =>
{
	let d_mode = props.default || props.modes[0].key;

	const [mode, setMode] = useState(d_mode);

	const onClick = (e) =>
	{
		const mode = e.currentTarget.getAttribute('data-mode');
		setMode(mode);

		if (props.onClick) props.onClick(mode);
	}

	const list = [];

	for (let i = 0; i < props.modes.length; i++)
	{
		let e = props.modes[i];

		let is_selected = e.key === mode;

		list[i] =
		(
			<li key={i}>
				<div className='mode-btn' data-mode={e.key} data-selected={(is_selected)?1:0} onClick={onClick}>
					<p className={'icon ' + e.icon} />
				</div>
			</li>
		);
	}

	return (
		<div className={'mode-btns ' + props.c} data-mode={mode}>
			<ul className='btns'>
				{list}
			</ul>
		</div>
	);
}

export const Accordion = (props) =>
{
	const [opened, setOpened] = useState(0);

	const toggle = () =>
	{
		const value = 1 - opened;

		$('#' + props.id).each(function(e)
		{
			let h = (value)?$(this).find('.content').outerHeight():0;
			$(this).find('.box-content').height(h);
		}
		);

		setOpened(value);
	}

	let className = classNames('box accordion', props.c);

	return (
		<div id={props.id} className={className} data-opened={opened}>
			<div className='box-title' onClick={toggle}>
				{props.title}
				<div className='btn toggle'>
					<p className='icon angle-down'/>
				</div>
			</div>
			<div className='box-content'>
				<div className='content'>
					{props.children}
				</div>
			</div>
		</div>
	);
}

export const ColumnBar = (props) =>
{
	const list = [];

	if (props.labels)
	{
		for (let i = 0; i < props.labels.length; i++)
		{
			let e = props.labels[i];

			list[i] = 
			(
				<li key={e.key} data-key={e.key}>
					<p className='label'>{e.name}</p>
				</li>
			);
		}
	}

	return (
		<div className='column-bar'>
			<ul className='cols'>
				{list}
			</ul>
		</div>
	);
}

export const Notes = (props) =>
{
	if (props.notes == null) return null;

	const list = [];

	props.notes.forEach((e, i) =>
	{
		list.push((<li>{e}</li>));
	}
	);

	return (
		<ul className='notes'>
			{list}
		</ul>
	);
}

//TEXT LAYOUT

export const SubSectionWNum = (props) =>
{
	return (
		<section className='sub w-num'>
			<div className='section-title'>
				<p className='num'>{'0' + props.num}</p>
				<h3 className='title'>{props.title}</h3>
			</div>
			<div className='section-content'>
				<div className='container'>
					{props.children}
				</div>
			</div>
		</section>
	);
}

export const SubSectionWIcon = (props) =>
{
	return (
		<section className='sub w-icon'>
			<div className='section-title'>
				<p className={'icon ' + props.icon}/>
				<h3 className='title'>{props.title}</h3>
			</div>
			<div className='section-content'>
				<div className='container'>
					{props.children}
				</div>
			</div>
		</section>
	);
}

export const BlockCols = (props) =>
{
	return (
		<div className={classNames('block cols img-txt', props.c, (props.r)?'reverse':'')}>
			<div className='col image'>
				{props.image}
			</div>
			<div className='col text'>
				{props.children}
			</div>
		</div>
	);
}

export const BlockImgTxt = (props) =>
{
	const image = (
		<figure>
			<img src={props.img} alt={props.title}/>
		</figure>
	)

	return (
		<BlockCols image={image} {...props}>
			<h3 className='col-title'>{props.title}</h3>
			<div className='text'>
				{props.children}
			</div>
		</BlockCols>
	);
}

export const BlockVideoTxt = (props) =>
{
	const image = (
		<YTVideo id={props['video-id']}/>
	)

	return (
		<BlockCols image={image} {...props}>
			{props.children}
		</BlockCols>
	);
}

//Youtube

export const YTVideo = (props) =>
{
	return (
		<div className='video-wrapper'>
			<iframe width="100%" height="100%" src={'https://www.youtube.com/embed/' + props.id + '?rel=0&autoplay=0'} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
		</div>
	);
}