//商品詳細

import React, { useEffect } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { MENU, LABELS } from '../_Params';
import { Screen, Accordion, Notes, VideoBtn } from '../_sections/_UI_Common';
import { WaterTemp, Price, Features, Specs } from '../_sections/_UI_Items';
import { IncludedServices, NonincludedServices, GuardianFeatures, FaucettesContent } from '../_sections/_UI_Parts';
import { getDocumentTitle, getTabNameFromKey, getItemByItemID, matchNumber, spBR } from '../_utils/_Utils';
import { useScreenConfig, useScreenTitle, useURLHistory } from '../_utils/_Hooks';
import parse from 'html-react-parser';
import settings from '../../json/settings.json';

export const ScreenItemDetail = (props) =>
{
	let { item_id } = useParams();
	item_id = item_id.replace(':', '');

	const e = getItemByItemID(item_id);

	const {screenConfig} = useScreenConfig();
	const {setScreenTitle} = useScreenTitle();
	const {setBack, saveQuery} = useURLHistory();

	let categoryName = '';
	if (e.series)
		categoryName = e.series + 'シリーズ';
	else if (e['is-option'])
		categoryName = 'オプション';

	let has_temp = (e['w-cold'] || e['w-room'] || e['w-hot']);

	const spec_keys = 
	[
		{
			name: '浄水方式',
			key: 'filter-type'
		},
		{
			name: 'タンク容量',
			key: 'capacity'
		},
		{
			name: '製品寸法',
			key: 'size'
		},
		{
			name: '設置スペースの目安',
			key: 'space'
		},
		{
			name: '重量',
			key: 'weight'
		},
		{
			name: '温度仕様',
			key: 'temp'
		},
		{
			name: '消費電力',
			key: 'wattage'
		}
	];

	let price_plans = [];

	price_plans[0] = 
	(
		<dl className='label-box'>
			<dt>{LABELS.PRICE_RENTAL}</dt>
			<dd>
				<Price value={e['price-wtax-standard']} period={true}/>
			</dd>
		</dl>
	);

	//初期設置費
	if (e['has-installation'])
	{
		price_plans[1] = 
		(
			<dl className='label-box'>
				<dt>{LABELS.PRICE_INSTALLATION}</dt>
				<dd>
					<Price value={settings.prices.installation}/>
				</dd>
			</dl>
		);
	}

	let description_notes = null;

	if (e.notes)
	{
		description_notes = (<Notes notes={e.notes.split(/\n\r?/)} />);
	}

	let price_notes = null;

	if (e.notes2)
	{
		price_notes = (<Notes notes={e.notes2.split(/\n\r?/)} />);
	}

	let video_btn = null;

	if (e['video-id'])
	{
		video_btn =
		(
			<div className='block videos mt30'>
				<VideoBtn c='c-b' video-id={e['video-id']}>{LABELS.ITEM_VIDEO}</VideoBtn>
			</div>
		)
	}

	const contents = [];

	if (item_id === 'faucets')
	{
		contents[0] = (
			<section className="faucets">
				<div className="container">
					<FaucettesContent/>
				</div>
			</section>
		);
	}
	else
	{
		if (e['sketchfab-id'])
		{
			let item_name;

			if (e.series)
			{
				item_name = (<>
					<div className='series'>
						{categoryName}
					</div>
					<div className='name-jp'>
						{spBR(e.name)}
					</div>
					<div className='name-en'>
						{e['series-en'].toUpperCase() + ' Series'}
						<span className="divider">|</span>
						{e['name-en']}
					</div>
				</>);
			}
			else
			{
				item_name = (<>
					<div className='name-jp'>
						{spBR(e.name)}
					</div>
					<div className='name-en'>
						{e['name-en']}
					</div>
				</>);
			}

			contents[0] = 
			(
				<section className="model">
					<div className="container">
						<div className='item-name'>
							{item_name}
						</div>
						<div className='model-container'>
							{/*
							<figure>
								<img src={e.img} alt={e.name}/>
							</figure>
							*/}
							<div className='model'>
								<div className="sketchfab-embed-wrapper">
									<iframe title={e['item-id']} frameBorder="0" allowFullScreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="fullscreen; autoplay; vr" xr-spatial-tracking="true" execution-while-out-of-viewport="true" execution-while-not-rendered="true" web-share="true" src={"https://sketchfab.com/models/" + e['sketchfab-id'] + "/embed?autospin=1&camera=0&transparent=1&ui_infos=0&ui_general_controls=0&ui_inspector=0&ui_settings=0&ui_help=0&ui_hint=0&ui_vr=0&ui_annotations=0&autostart=1"}> </iframe>
								</div>
							</div>
						</div>
					</div>
				</section>
			);
		}

		contents[1] = 
		(
			<section className="main-info">
				<div className="container">
					{e['price-wtax-standard'] > 0 &&
						<div className='block prices'>
							{price_plans[0]}
							{price_plans[1]}
						</div>
					}
					{price_notes &&
						<div className='block price-notes mt10'>
							{price_notes}
						</div>
					}
					<div className='item-lead mt30'>
						{e['detail-lead']}
					</div>
					<div className='description'>
						{e['detail-desc']}
						{description_notes}
					</div>
					{has_temp &&
						<WaterTemp c='h l-h mt30' has-label={true} data={e}/>
					}
				</div>
			</section>
		);

		/*
		if (has_temp || video_btn)
		{
			contents[contents.length] = (<hr/>);
		}
		*/

		if (video_btn)
		{
			contents[contents.length] = 
			(
				<section className="video">
					<div className="container">
						{video_btn}
					</div>
				</section>
			);
		}

		if (e['features-html-data'])
		{
			contents[contents.length] =
			(
				<>
					{parse(e['features-html-data'])}
				</>
			);
		}

		if (e['has-specs'])
		{
			contents[contents.length] =
			(
				<section className="specs">
					<div className="container">
						<div className='block specs'>
							<Specs data={e} keys={spec_keys}/>
						</div>
						<div className='block includes mt30'>
							<Accordion id={'included-services'} c={'services'} title={<p>{'【' + LABELS.INCLUDED_INSTALLATION + '】'}</p>}>
								<IncludedServices/>
							</Accordion>
						</div>
						<div className='block non-includes mt20'>
							<ul className='notes'>
								<NonincludedServices/>
							</ul>
						</div>
					</div>
				</section>
			);
		}
	}

	useEffect(() =>
	{
		screenConfig();

		const itemName = (e)?e.name:'';

		document.title = getDocumentTitle(MENU.CATALOG, categoryName, itemName);
		setScreenTitle(MENU.CATALOG, categoryName, itemName);

		setBack('/catalog');
		saveQuery(item_id);
	}
	, [e.name, item_id]);

	return (
		<Screen id='item-detail' {...props}>
			<div className="card-page item-detail">
				{contents}
			</div>
		</Screen>
	);
}

export default ScreenItemDetail;