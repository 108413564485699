import { DOCUMENT_TITLE } from '../_Params';
import param_stands from '../../json/params_stands.json';
import parse from 'html-react-parser';

export const getDocumentTitle = function()
{
	let args = [ DOCUMENT_TITLE ];

	for (let i = 0; i < arguments.length; ++i) 
		if (arguments[i]) args.push(arguments[i]);

	return args.join(' | ');
}

export const getTabNameFromKey = (key, tabs) =>
{
	let e1 = tabs.find(e => e.key === key);
	if (e1)
		return e1.name;
	else
		return tabs[0].name;
}

const getKeysFromItem = (e, keys, smallImg) =>
{
	let e2 = {};

	const toImg = (img_id) =>
	{
		return '/img/products/' + img_id + (smallImg?'_s':'') + '.png';
	}

	if (keys === undefined || keys.length === 0)
	{	
		for (let key in e)
		{
			if (key === 'img-id')
				e2['img'] = toImg(e['img-id']);
			e2[key] = e[key];
		}
	}
	else
	{
		keys.forEach((key) =>
		{
			if (key === 'img')
				e2['img'] = toImg(e['img-id']);
			else
				e2[key] = e[key];
		}
		);
	}

	return e2;
}

export const getItemsByID = (ids, keys, smallImg) =>
{
	const array = [];
	
	for (let i = 0; i < ids.length; i++)
	{
		let id = ids[i];

		let e1 = param_stands.find(e => e.id === id);
		if (e1 === null) continue;

		let e2 = getKeysFromItem(e1, keys, smallImg);
		array.push(e2);
	}

	return array;
}

export const getItemByItemID = (item_id, keys, smallImg) =>
{
	let e1 = param_stands.find(e => e['item-id'] === item_id);

	return getKeysFromItem(e1, keys, smallImg);
}

export const getItemsBySeries = (series, keys, smallImg) =>
{
	const array = [];
	
	for (let i = 0; i < param_stands.length; i++)
	{
		let e1 = param_stands[i];
		if (e1['series-en'] !== series) continue;

		let e2 = getKeysFromItem(e1, keys, smallImg);
		array.push(e2);
	}

	return array;
}

export const getItemsByMatch = (matches, keys, smallImg) =>
{
	const array = [];
	
	for (let i = 0; i < param_stands.length; i++)
	{
		let e1 = param_stands[i];
		
		if (!matches.every((key) => e1[key])) continue;

		let e2 = getKeysFromItem(e1, keys, smallImg);
		array.push(e2);
	}

	return array;
}

export const convertSpecValue = (value, unit) =>
{
	if (matchNumber(value))
		return value + unit;
	else
		return value;
}

export const matchNumber = (value) =>
{
	return value &&  /^[0-9\/\-\.\,]+$/.test(value);
}

export const spBR = (value) =>
{
	return parse(value.replace(/ /g, '<br class="space"/>'));
}

export const getScreenTitleFromPath = function()
{
	let args = [];

	for (let i = 0; i < arguments.length; ++i)
	{
		if (arguments[i])
		{
			let depth = (<>
				{ args.length > 0 &&(<span className='hyphen'>-</span>) }
				<span className='title'>{arguments[i]}</span>
			</>);

			args.push((<span className='depth' data-index={args.length} key={args.length}>{depth}</span>));
		}
	}
		
	return args;
}

export const convertPrefecture = function(str)
{
	switch(str)
	{
		case '東京':
			str += '都';
		break;
		case '北海道':
		break;
		case '大阪':
		case '京都':
			str += '府';
		break;
		default:
			str += '県';
		break;
	}
	return str;
}