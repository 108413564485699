import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { AppMainContext } from '../../App';
import { getScreenTitleFromPath } from './_Utils';

export const useScreenTitle = () =>
{
	const { headerControl } = useContext(AppMainContext);
	
	const setScreenTitle = function()
	{
		let title = getScreenTitleFromPath.apply(this, arguments);

		headerControl.setTitle(title);
	}

	return { setScreenTitle };
}

export const useScreenConfig = () =>
{
	const { footerControl } = useContext(AppMainContext);
	
	const screenConfig = function(config)
	{
		if (config === undefined) config = {};

		let hasFooterHelp = !(config.hasFooterHelp === false);

		//footerControl.hasHelp(hasFooterHelp)
		footerControl.hasHelp(false)
	}

	return { screenConfig };
}

export class PageDirection
{
	static depth = 0;
	static keys = [];
}

export const usePageDirection = () =>
{
	const location = useLocation();
	const history = useHistory();

	let depth = (location.pathname === '/')?0:location.pathname.split('/').length - 1;
	
	let direction = 1;
	let browser_state = 0;

	let prevKeys = PageDirection.keys;

	//URL Navigate
	if (history.action === 'PUSH' || !prevKeys)
	{
		let prevDepth = PageDirection.depth;
		direction = depth < prevDepth?-1:1;
	}
	//Browser back/next
	else if (history.action === 'POP')
	{
		direction = 0;
	}

	useEffect(() =>
	{
		PageDirection.depth = depth;

		if (!PageDirection.keys || PageDirection.keys.every((v) => v === undefined))
			PageDirection.keys = [];

		if (browser_state === 1)
			PageDirection.keys.shift();
		else if (browser_state === -1)
			PageDirection.keys.unshift(location.key);
		else
			PageDirection.keys = [location.key];
	}
	);

	return [direction, depth];
}

export class URLHistory
{
	static backURL = '';
	static backHasQuery = false;

	static queries = {};

	static setBackURL(value, hasQuery)
	{
		this.backURL = value;
		this.backHasQuery = hasQuery;
	}

	static clearQuery(location)
	{
		this.queries[location.pathname] = null;
	}

	static getURL()
	{
		let url = this.backURL;

		if (!url) return null;

		const q = this.queries[url];

		if (this.backHasQuery && q)
		{
			if (q.id) url += q.id;
			if (q.search) url += q.search;
			if (q.hash) url += q.hash;
		}

		return url;
	}

	static setQuery(id, location)
	{
		this.queries[location.pathname] = 
		{
			path: location.pathname,
			id: id,
			search: location.search,
			hash: location.hash
		}
	}
}

export const useURLHistory = () =>
{
	const location = useLocation();
	const history = useHistory();

	const setBack = (url, hasQuery) =>
	{
		if (hasQuery === undefined) hasQuery = true;

		URLHistory.setBackURL(url, hasQuery);
	}

	const saveQuery = (id) =>
	{	
		URLHistory.setQuery(id, location);
	}

	const clearQuery = () =>
	{	
		URLHistory.clearQuery(location);
	}

	const goBack = () =>
	{
		const url = URLHistory.getURL();

		if (url) history.push(url);
	}

	return {setBack, saveQuery, clearQuery, goBack};
}