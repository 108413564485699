import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { MENU } from '../_Params';
import { Screen, ModeBtns } from '../_sections/_UI_Common';
import { SERIES_DESCRIPTION } from '../_sections/_UI_Parts';
import { ScreenTabs } from '../_sections/_UI_Tabs';
import { ItemCard } from '../_sections/_UI_Items';
import { getDocumentTitle, getTabNameFromKey, getItemsBySeries, getItemsByMatch } from '../_utils/_Utils';
import { useScreenConfig, useScreenTitle, useURLHistory } from '../_utils/_Hooks';
import classNames from 'classnames/bind';
import $ from 'jquery';

const ScreenCatalog = (props) =>
{
	const location = useLocation();
	const history = useHistory();

	let query = new URLSearchParams(location.search);

	const tabID = query.get("tab");
	const view = query.get("view") || 'list';

	const {screenConfig} = useScreenConfig();
	const {setScreenTitle} = useScreenTitle();
	const {setBack, saveQuery} = useURLHistory();

	const getStandItems = (series, view, index) =>
	{
		const params = getItemsBySeries(series, []);

		params.sort((a, b) => (a.id > b.id)?1:-1);

		const items = [];

		for (let i = 0; i < params.length; i++)
		{
			let p = params[i];

			if (!p['is-stand']) continue;

			let item =
			(
				<li key={view + i} data-index={i}>
					<ItemCard data={p} has-temp={true} type={series} style={view} to={getLink(p)}/>
				</li>
			);

			items.push(item);
		}
		
		let description = SERIES_DESCRIPTION[series.toUpperCase()];

		return (<>
			<div className={'tab-content-top'}>
				<div className={'balloon description'} data-index={index}>
					<p>{description}</p>
					<div className='tip top'></div>
				</div>
			</div>
			<ul className={'card-list products animate'} data-type={series} data-style={view} data-cols={3}>
				{items}
			</ul>
		</>);
	}

	const getOtherItems = (view) =>
	{
		const params = getItemsByMatch(['is-option']);

		const items = [];

		for (let i = 0; i < params.length; i++)
		{
			let p = params[i];

			let item =
			(
				<li key={view + i} data-index={i}>
					<ItemCard data={p} type={'other'} style={view} to={getLink(p)}/>
				</li>
			);

			items.push(item);
		}

		return (
			<ul className={'card-list other animate'} data-type={'other'} data-style={view} data-cols={3}>
				{items}
			</ul>
		);
	}

	const getLink = (p) =>
	{
		return (p['has-detail'])?'/catalog/item:' + p['item-id']:'';
	}

	const items = [];
	
	//nano
	items[0] = getStandItems('nano', view, 0);
	//premium
	items[1] = getStandItems('premium', view, 1);
	//other
	items[2] = getOtherItems(view);

	const tabs = [];

	tabs[0] =
	{
		name: 'ナノシリーズ',
		key: 'nano',
		content: (
			<div className='container'>
				{items[0]}
			</div>
		)
	}

	tabs[1] =
	{
		name: 'プレミアムシリーズ',
		key: 'premium',
		content: (
			<div className='container'>
				{items[1]}
			</div>
		)
	}

	tabs[2] =
	{
		name: 'オプション',
		key: 'options',
		content: (
			<div className='container'>
				<ul className='card-list options animate' data-type='options' data-style='list'>
					{items[2]}
				</ul>
			</div>
		)
	}

	const modes = 
	[
		{
			key: 'list',
			icon: 'list-view'
		},
		{
			key: 'grid',
			icon: 'grid-view'
		},
	];

	const modesOnClick = (mode) =>
	{
		query.set('view', mode);
		const url = location.pathname + '?' + query.toString();
		history.replace(url);
	}

	const bar_right =
	(
		<div className='col right'>
			<ModeBtns c='view' modes={modes} default={view} onClick={modesOnClick}/>
		</div>
	);

	useEffect(() =>
	{
		const tabName = getTabNameFromKey(tabID, tabs);

		document.title = getDocumentTitle(MENU.CATALOG, tabName);
		setScreenTitle(MENU.CATALOG, tabName);
	}
	, [tabID]);

	useEffect(() =>
	{
		screenConfig();
		setBack('/');
		saveQuery();
	}
	, [tabID, view]);

	const onComplete = () =>
	{
		$('ul.card-list >li').addClass('is-in');
	}
	
	return (
		<Screen id='catalog' onComplete={onComplete} {...props}>
			<ScreenTabs n={'catalog'} tabs={tabs} bar-right={bar_right}/>
		</Screen>
	);
}

export default ScreenCatalog;