import React, { useState, useEffect } from 'react';
import { MENU } from '../_Params';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { AppContext } from '../../App';
import { Screen, Font } from '../_sections/_UI_Common';
import { getDocumentTitle, getTabNameFromKey, convertPrefecture } from '../_utils/_Utils';
import JapanMap from '../_sections/_JapanMap';
import { useScreenConfig, useScreenTitle, useURLHistory } from '../_utils/_Hooks';
import $ from 'jquery';

export const ScreenShowroom = (props) =>
{
	const location = useLocation();
	const history = useHistory();

	let query = new URLSearchParams(location.search);

	const areaID = query.get("area-id") || 0;

	const {screenConfig} = useScreenConfig();
	const {setScreenTitle} = useScreenTitle();
	const {setBack, saveQuery, clearQuery} = useURLHistory();

	const {showroomAreas, showroomPrefectures} = React.useContext(AppContext);

	const areas = showroomAreas.current;
	const prefectures =showroomPrefectures.current;

	const setArea = (area_id, prefecture_id) =>
	{
		let url;

		if (area_id > 0 && prefecture_id > 0)
			url = getShowroomURL(area_id, prefecture_id);
		else if (area_id > 0)
			url = getZoomedInURL(area_id);
		else
			url = getZoomedOutURL();

		history.push(url);
	}

	const getZoomedOutURL = () =>
	{
		return '/showroom';
	}

	const getZoomedInURL = (area_id) =>
	{
		return '/showroom?area-id=' + area_id;
	}

	const getShowroomURL = (area_id, prefecture_id) =>
	{
		return '/showroom/area?area-id=' + area_id + '&tab=' + prefecture_id;
	}

	const locationList = () =>
	{
		const list = [];
		let i;

		if (areaID == 0)
		{
			areas.forEach((e) =>
			{
				list.push((
					<li
						key={areaID + '-' + e.id}
						className='location-link area animate'
						data-id={e.id}
						data-name={e.name}
						data-active={e.num_showrooms > 0?1:0}
					>
						<Link to={getZoomedInURL(e.id)}>
							<div className='label'>
								<div className='text'>{e.name}</div>
							</div>
						</Link>
					</li>
				));
			}
			);
		}
		else
		{
			prefectures.forEach((e) =>
			{
				if (e.area_id == areaID)
				{
					list.push((
						<li
							key={areaID + '-' + e.id}
							className='location-link prefecture animate'
							data-id={e.id}
							data-name={e.name}
							data-active={e.num_showrooms > 0?1:0}
						>
							<Link to={getShowroomURL(e.area_id, e.id)}>
								<div className='label'>
									<div className='text'>{e.name}</div>
								</div>
							</Link>
						</li>
					));
				}
			}
			);
		}

		return (
			<ul className="list">
				{list}
			</ul>
		);
	}

	useEffect(() =>
	{
		screenConfig();

		let e = areas[areaID - 1];

		const areaName = (e)?e.name:'';

		document.title = getDocumentTitle(MENU.SHOWROOM, areaName);
		setScreenTitle(MENU.SHOWROOM, areaName);

		if (!e)
		{
			setBack('/', false);
			clearQuery();
		}
		else
		{
			setBack('/showroom', false);
			saveQuery();
		}
	}
	, [areaID]);

	useEffect(() =>
	{
		prefectures.forEach((e) =>
		{
			$('svg g#japan-map path[data-name=' + e.en + ']').attr('data-active', e.num_showrooms > 0?1:0);
		});
	}
	, [prefectures]);

	const onComplete = () =>
	{
		$('li.location-link').addClass('is-in');
	}

	return (
		<Screen id='showroom' onComplete={onComplete} {...props}>
			<div className="card-page showroom-top">
				<section className='map'>
					<JapanMap area-id={areaID} setArea={setArea}/>
				</section>
				<section className='location'>
					<div className='container'>
						{locationList()}
					</div>
				</section>
			</div>
		</Screen>
	);
}

export default ScreenShowroom;