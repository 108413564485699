import { BlockImgTxt, Accordion } from './_UI_Common';
import { Price } from '../_sections/_UI_Items';
import classNames from 'classnames/bind';
import parse from 'html-react-parser';

export const PhoneNum = (props) =>
{
	let phone_num = props.num || '0120-032-114';

	phone_num = phone_num.split(/\-/).join('<span className="hyphen">-</span>');

	return (
		<div className={classNames('phone-num support', props.c)}>
			<p className='icon phone'/>
			<p className='num'>{parse(phone_num)}</p>
		</div>
	);
}

export const CreditCards = (props) =>
{
	const icons = ['nicos', 'jcb', 'visa', 'mastercard', 'diners_club', 'mufg_card', 'amex'];

	const list = [];
	
	icons.forEach((str, i) =>
	{
		list[i] = (
			<li key={str}>
				<img src={'/img/creditcards/' + str + '.png'} alt={str}/>
			</li>
		);
	}
	);

	return (
		<ul className={classNames('icons creditcards', props.className)}>
			{list}
		</ul>
	);
}

export const SERIES_DESCRIPTION = 
{
	NANO: (<>
		ナノトラップ<span className="sup">TM</span>フィルターによる静電吸着で、ウィルスやバクテリアまでも除去します。不純物をしっかり除去する一方で、ミネラル分は残して安心でおいしいお水に。
	</>),
	PREMIUM: (<>
		水分子以外の不純物を約99.9％除去可能なROフィルター（逆浸透膜）で、純水（ピュアウォーター）を作り出します。
	</>)
}

export const IncludedServices = () =>
{
	return (
		<ul className='dotted'>
			<li key='1'>本体の設置</li>
			<li key='2'>分岐水栓金具</li>
			<li key='3'>設置部材</li>
			<li key='4'>出張料金</li>
			<li key='5'>廃材撤去費用</li>
		</ul>
	);
}

export const NonincludedServices = () =>
{
	return (
		<>
			<li key='1'>本体設置から１年未満で撤去する場合は、6,600円（税込）の撤去費用がかかります。</li>
			<li key='2'>本体設置から１年未満で機種変更する場合は、9,900円（税込）の機種変更手数料がかかります。</li>
			<li key='3'>引越し等による再設置の場合は、移設費用がかかりません。</li>
		</>
	);
}

export const GuardianFeatures = () =>
{
	const datas = 
	[
		{
			'title': '必要なときだけ電気を使う、賢いECOモデル',
			'text': '新技術である『瞬間冷温水』機能により、タンク内のお水を保温・保冷するために電気を使わずにすみます。製品の仕組み自体がとってもECOです。',
			'img':'/img/catalog/guardian_feature_01.jpg',
			'r': false
		},
		{
			'title': '冷水・温水・常温水 を自由に',
			'text': '『 美味しいお水を、簡単で、楽しく。』　いろいろな温度で、お水を自由に使えます。高機能な技術により使い勝手が更によくなりました。<br/>温水に関しては、高温（93℃）・中高温（85℃）・低温（75℃）が選べるので、コーヒー、お茶、粉ミルクなど用途に応じて使い分けることができます。',
			'img':'/img/catalog/guardian_feature_02.jpg',
			'r': true
		},
		{
			'title': '省スペースにフィットするスリムなデザイン',
			'text': '『瞬間冷温水』機能により、冷水タンクと温水タンクが不要になったことで、横幅と奥行きが “ 23cm × 23cm ” とスリムなボディを実現。省スペースのキッチンや給湯室にもフィットします。',
			'img':'/img/catalog/guardian_feature_03.jpg',
			'r': false
		},
		{
			'title': '操作は、わかりやすいタッチ式',
			'text': 'お水の抽出方法はタッチ式です。はじめて操作する方にも直感的に使えるインターフェースで、ご家庭はもちろん、オフィスの給湯室、レストランなどでもご利用しやすくなっています。',
			'img':'/img/catalog/guardian_feature_04.jpg',
			'r': true
		},
		{
			'title': '可動する抽出口で、マイボトルでも、お鍋でも',
			'text': '抽出口を上下に動かせるので、背の高いステンレスボトルにお水を入れるのも、お鍋にお湯を入れるのにも便利。更に、受け皿も簡単に外せます。',
			'img':'/img/catalog/guardian_feature_05.jpg',
			'r': false
		},
		{
			'title': '高機能フィルターシステムで水道水が生まれ変わる',
			'text': 'NASAが技術特許を保有する「ナノトラップフィルター」を搭載。<br/>原水中の浮遊物質、粒子性重金属（水銀、鉛、鉄、アルミ）およびバクテリア（大腸菌）のような微生物を選択的に除去します。<br/>さらに、ニオイ誘発物質を除去するイノセンスフィルターでお水の飲み心地を調え、美味しく仕上げます。',
			'img':'/img/catalog/guardian_feature_06.jpg',
			'r': true
		}
	];

	const list = [];

	for (let i = 0; i < datas.length; i++)
	{
		let e = datas[i];

		list[i] = (
			<BlockImgTxt key={i} title={e.title} img={e.img} r={e.r}>
				{parse(e.text)}
			</BlockImgTxt>
		);
	}

	return (
		<>
			{list}
		</>
	);
}

export const FaucettesContent = () =>
{
	const datas = 
	[
		{
			'name': '混合水栓シングルレバー',
			'price': 5500,
			'img':'/img/products/options_faucet_1.png'
		},
		{
			'name': '混合水栓シャワー付きシングルレバー（スリムタイプ）',
			'price': 8250,
			'img':'/img/products/options_faucet_2.png'
		},
		{
			'name': '混合水栓シャワー付きシングルレバー（高級タイプ）',
			'price': 11000,
			'img':'/img/products/options_faucet_3.png'
		}
	];

	const list = [];

	for (let i = 0; i < datas.length; i++)
	{
		let e = datas[i];

		let name = e.name.replace(/(（)/, '<span className="type">$1').replace(/(）)/, '$1</span>');

		list[i] = (
			<li className='product-item' key={i}>
				<div className={classNames('block cols product')}>
					<div className='col image'>
						<img src={e.img} alt={e.name}/>
					</div>
					<div className='col text'>
						<div className='name'>
							{parse(name)}
						</div>
						<div className='price-info'>
							<Price value={e.price}/>
							<p className='price-note'>（水栓料金・設置料金込み）</p>
						</div>
					</div>
				</div>
			</li>
		);
	}

	return (
		<>
			<ul className='product-items'>
				{list}
			</ul>
			<ul className='notes mt20'>
				<li key='1'>
					※ 水栓のみでの販売はしておりません。ウォータースタンドのお申込み時、または設置時に直接お申し込みください。
				</li>
				<li key='2'>
					※ 新規ご契約のお客様の場合、設置代金は無料です。既存のお客様の場合、設置代金を別途11,000円（税込）いただきます。
				</li>
			</ul>
		</>
	);
}