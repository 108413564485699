import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import { MENU } from '../_Params';
import { Btn, BtnLabel, HelpBtn, LockBtn, RoundCloseBtn } from './_UI_Common';
import { AppMainContext } from '../../App';
import classNames from 'classnames/bind';
import settings from '../../json/settings.json';
import parse from 'html-react-parser';
import YouTube from 'react-youtube';

ReactModal.setAppElement("#root");

export const Modal = (props) =>
{
	const {modalControl, videoControl, lockControl} = useContext(AppMainContext);
	
	const transitionDuration = 500;

	const className =
	{
		base: classNames("content-base", props.id),
		afterOpen: "after",
		beforeClose: "before"
	}

	const overlayClassName = 
	{
		base: classNames("overlay-base", props.id),
		afterOpen: "after",
		beforeClose: "before"
	}

	const doClose = () =>
	{
		modalControl.close();
	}

	const doLock = () =>
	{
		modalControl.close();
		lockControl.lock();
	}
	
	return(
		<ReactModal
			isOpen={props['is-open']}
			className={className}
			overlayClassName={overlayClassName}
			closeTimeoutMS={transitionDuration}
			onRequestClose={doClose}
			data-id={props.id}
		>
			<button className={'btn-close'} onClick={doClose}>
				<p className='icon close'/>
			</button>
			<div className='modal-container'>
				{props.children}
			</div>
			<LockBtn onClick={doLock}/>
		</ReactModal>
	);
}

export const VideoModal = (props) =>
{
	const {videoControl} = useContext(AppMainContext);
	
	const transitionDuration = 400;

	const className =
	{
		base: classNames("content-base", props.id),
		afterOpen: "after",
		beforeClose: "before"
	}

	const overlayClassName = 
	{
		base: classNames("overlay-base", props.id),
		afterOpen: "after",
		beforeClose: "before"
	}

	const doClose = () =>
	{
		videoControl.close();
	}

	const onPause = () =>
	{
	}
	
	const onEnd = () =>
	{
		videoControl.close();
	}

	const opts =
	{
		height: '100%',
		width: '100%',
		playerVars:
		{
			autoplay: 1,
			rel: 0,
			modestbranding: true
		},
    };
	
	return(
		<ReactModal
			isOpen={props['is-open']}
			bodyOpenClassName={'VideoModal__Body--open'}
			portalClassName={'VideoModalPortal'}
			className={className}
			overlayClassName={overlayClassName}
			closeTimeoutMS={transitionDuration}
			onRequestClose={doClose}
		>
			<div className='modal-container'>
				<YouTube
					videoId={props['video-id']}
					className={'yt-video'}
					containerClassName={'video-container'}
					opts={opts}
					onPause={onPause}
					onEnd={onEnd}
				/>
				<RoundCloseBtn onClick={doClose}/>
			</div>
		</ReactModal>
	);
}

export const ModalMenu = (props) =>
{
	const {modalControl, videoControl} = useContext(AppMainContext);

	const closeModal = (url) =>
	{
		modalControl.close();
	}

	const playVideo = (id) =>
	{
		modalControl.close();
		videoControl.open(id);
	}

	const MenuBtn = (props) =>
	{
		return (
			<Link className='btn menu' to={props.to} onClick={closeModal}>
				<BtnLabel {...props}>
					{props.children}
				</BtnLabel>
			</Link>
		);
	}

	const ModalVideoBtn = (props) =>
	{
		const video_id = props['video-id'];

		return (
			<button className='btn menu video' onClick={() => playVideo(video_id)}>
				<BtnLabel {...props}>
					{props.children}
				</BtnLabel>
			</button>
		);
	}

	const getVideoLinks = () =>
	{
		const list = [];

		for (let i = 0; i < settings.top.videos.length; i++)
		{
			let e = settings.top.videos[i];
			let btn = (
				<ModalVideoBtn key={i} n={'video-' + (i + 1)} icon='youtube' video-id={e.id}>
					{parse(e.title)}
				</ModalVideoBtn>
			);
			list.push(btn);
		}

		return list;
	}

	return (
		<div className='modal-content menu'>
			<div className='container'>
				<section className='first'>
					<MenuBtn icon='home' to='/'>トップへ戻る</MenuBtn>
				</section>
				<section className='catalog'>
					<MenuBtn icon='book' to='/catalog'>{MENU.CATALOG}</MenuBtn>
				</section>
				<section className='video'>
					{getVideoLinks()}
				</section>
				<section className='links tb-pc-only'>
					<MenuBtn to='/setup'>{MENU.SETUP1}</MenuBtn>
					<MenuBtn to='/setup?tab=support'>{MENU.SETUP2}</MenuBtn>
					<MenuBtn to='/order'>{MENU.ORDER1}</MenuBtn>
					<MenuBtn to='/order?tab=price'>{MENU.ORDER2}</MenuBtn>
					<MenuBtn to='/price-list'>{MENU.PRICELIST}</MenuBtn>
					<MenuBtn to='/showroom'>{MENU.SHOWROOM}</MenuBtn>
				</section>
				<section className='links sp-only'>
					<MenuBtn to='/setup'>{MENU.SETUP}</MenuBtn>
					<MenuBtn to='/order'>{MENU.ORDER}</MenuBtn>
					<MenuBtn to='/price-list'>{MENU.PRICELIST}</MenuBtn>
					<MenuBtn to='/showroom'>{MENU.SHOWROOM}</MenuBtn>
				</section>
				<section className='help'>
					<HelpBtn/>
				</section>
			</div>
		</div>
	);
}