import * as THREE from "three";

export default class Water
{
	constructor(params)
	{
		if (params.el === null || params.container === null) return null;

		this.bindAll();

		//Size of canvas
		this.el = document.querySelector(params.el);
		//Canvas wrapper
		this.container = document.querySelector(params.container);

		this.tex_img = '';
		this.map_img = '';

		this.dispValue = 0;
		this.speedX = 0.003;
		this.speedY = 0.003;

		this.intensity = 0.25;

		this.srcSize =  { w:768, h:1024 };

		for (let key in params)
		{
			if (key === 'el' || key === 'container') continue;
			this[key] = params[key];
		}

		this.renderer = null;
		this.scene = null;
		this.clock = null;
		this.camera = null;	

		this.state =
		{
			animating: false,
			text: false,
			initial: true
		};

		this.texture = null;

		this.vertexShader = `
			varying vec2 vUv;

			void main() {
				vUv = uv;
				gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
			}
		`;
		this.fragmentShader = `
			varying vec2 vUv;

			uniform sampler2D texture1;
			uniform sampler2D disp;

			uniform float intensity;
			uniform float offsetX;
			uniform float offsetY;
			
			void main() {
				vec2 uv = vUv;
				vec2 uv2 = vec2(uv.x + offsetX, uv.y + offsetY);

				float power = 0.5;
				vec4 _disp = texture2D(disp, uv2);
				vec2 dispVec = vec2(-power, -power) + vec2(_disp.r, _disp.r) * 2.0 * power;
				vec2 distPos = uv + dispVec * intensity;

				gl_FragColor = texture2D(texture1, distPos);
			}
		`;

		this.init();
	}

	init()
	{
		this.scene = new THREE.Scene();
		this.clock = new THREE.Clock(true);
		this.renderer = new THREE.WebGLRenderer({ alpha: true });

		this.attach();
		this.setSize();
		this.cameraSetup();
		this.loadTextures();
		this.createMesh();

		this.render();
		this.initResize();
	}

	initResize()
	{
		window.addEventListener('resize', (e) =>
		{
			this.setSize();
		}
		);
	}

	start()
	{
		this.iid = window.setInterval(() =>
		{
			this.update();
		}
		, 30);
	}

	stop()
	{
		clearInterval(this.iid);
	}

	mount()
	{
		this.attach();
	}

	unmount()
	{
		this.stop();
		this.remove();
	}

	setSize()
	{
		let w = window.innerWidth;
		let h = window.innerHeight;

		let rw, rh;

		if (w / h > this.srcSize.w / this.srcSize.h)
		{
			rw = w;
			rh = w * this.srcSize.h / this.srcSize.w;
		}
		else
		{
			rh = h;
			rw = h * this.srcSize.w / this.srcSize.h;
		}

		if (this.renderer)
		{
			this.renderer.setPixelRatio(window.devicePixelRatio);
			this.renderer.setSize(rw, rh);
		}
		
		if (this.camera)
		{
			this.camera.aspect = rw / rh;
  			this.camera.updateProjectionMatrix();
		}
	}

	attach()
	{
		this.container.appendChild(this.renderer.domElement);
	}

	remove()
	{
		this.container.removeChild(this.renderer.domElement);
	}

	bindAll()
	{
		['render'].forEach(fn => this[fn] = this[fn].bind(this));
	}

	cameraSetup()
	{
		let w = this.el.offsetWidth;
		let h = this.el.offsetHeight;

		this.camera = new THREE.OrthographicCamera(-w/2, w/2, h/2, -h/2, 1, 1000);

		this.camera.lookAt(this.scene.position);
		this.camera.position.z = 1;
	}

	loadTextures()
	{
		const manager = new THREE.LoadingManager();

		manager.onLoad = () =>
		{
			this.start();
		}

		const loader = new THREE.TextureLoader(manager);
		loader.crossOrigin = '';

		this.texture = loader.load(this.tex_img + '?v=' + Date.now(), this.render);
		this.texture.minFilter = THREE.LinearFilter;

		this.disp = loader.load(this.map_img + '?v=' + Date.now(), this.render);
		this.disp.magFilter = this.disp.minFilter = THREE.LinearFilter;
		this.disp.wrapS = this.disp.wrapT = THREE.RepeatWrapping;
	}

	createMesh()
	{
		let gw = this.el.offsetWidth;
		let gh = this.el.offsetHeight;

		this.mat = new THREE.ShaderMaterial(
		{
			uniforms:
			{
				intensity: { type: 'f', value: this.intensity },
				texture1: { type: 't', value: this.texture },
				disp: { type: 't', value: this.disp },
				offsetX: {type: 'f', value: 0.0 },
				offsetY: {type: 'f', value: 0.0 }
			},
			transparent: true,
			vertexShader: this.vertexShader,
			fragmentShader: this.fragmentShader
		}
		);

		const geometry = new THREE.PlaneBufferGeometry(gw, gh, 1);
		const mesh = new THREE.Mesh(geometry, this.mat);

		this.scene.add(mesh);
	}

	update()
	{
		this.mat.uniforms.offsetX.value += this.speedX;
		this.mat.uniforms.offsetY.value += this.speedY;

		this.render();
	}

	// Render the scene
	render()
	{
		this.renderer.render(this.scene, this.camera);
	}
}