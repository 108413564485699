//お申し込み

import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MENU, URLS } from '../_Params';
import { getDocumentTitle, getTabNameFromKey } from '../_utils/_Utils';
import { Screen, SubSectionWIcon, BlockCols, Btn, VideoBtn, ReturnTopBtn, HelpBtn, BtnLabel, Font } from '../_sections/_UI_Common';
import { ScreenTabs } from '../_sections/_UI_Tabs';
import { PhoneNum, CreditCards, IncludedServices, NonincludedServices } from '../_sections/_UI_Parts';
import { useScreenConfig, useScreenTitle, useURLHistory } from '../_utils/_Hooks';
import $ from 'jquery';
import settings from '../../json/settings.json';

const ScreenHelpOrder = (props) =>
{	
	const location = useLocation();

	const {screenConfig} = useScreenConfig();
	const {setScreenTitle} = useScreenTitle();
	const {setBack, saveQuery, clearQuery} = useURLHistory();

	let query = new URLSearchParams(location.search);
	let tabID = query.get("tab");

	//const hasFooterUI = !(!tabID || tabID === 'order');
	const hasFooterUI = true;

	const tabs = [];

	tabs[0] = 
	{
		name: 'お申し込み',
		key: 'order',
		content: (
			<div className="container">
				<div className="card-page w-title order">
					<div className="card-tip"></div>
					<div className="card-title">
						<h2>お申し込み</h2>
					</div>
					<section className='contact'>
						<div className="container">
							<div className='block video mb20'>
								<VideoBtn c='c-b' video-id={settings.order.videos.order.id}>お申し込み説明動画を見る</VideoBtn>
							</div>
							<div className='box bordered center phone'>
								<div className='title mb20'>
									<h4>お電話でのお申込み</h4>
								</div>
								<PhoneNum c='l'/>
								<p className='note center mt20'>
									新規お申し込みのご相談、故障、アフターサービス、<br className='pc-only'/>
									お支払い・契約内容の確認などご相談できます。
								</p>
							</div>
							<div className='box bordered center web mt30'>
								<div className='title mb20'>
									<h4>WEBでのお申込み</h4>
								</div>
								<figure className='qr-code'>
									<img src='/img/order/qr_order.svg'/>
								</figure>
								<Btn c='big link c-w order' href={URLS.ORDER} target='_blank'>
									<BtnLabel icon='external-link'>
										<Font n='jp1'>お申し込み</Font>
									</BtnLabel>
								</Btn>
								<p className='note center mt20'>
									各種製品の
									<a href={URLS.ORDER} target='_blank'>お申し込みページ</a>
									です。
								</p>
							</div>
							<div className='box bordered center help mt30'>
								<div className='title mb20'>
									<h4>オンライン接客でのお申込み</h4>
								</div>
								<HelpBtn/>
							</div>
						</div>
					</section>
					<section className='link'>
						<div className="container">
							<ReturnTopBtn/>
						</div>
					</section>
				</div>
			</div>
		)
	}

	tabs[1] = 
	{
		name: '料金について',
		key: 'price',
		content: (
			<div className="container">
				<div className="card-page w-title price">
					<div className="card-tip"></div>
					<div className="card-title">
						<h2>料金について</h2>
					</div>
					<section className='price'>
						<SubSectionWIcon icon='checkbox' title={'機種別レンタル料金'}>
							<p>
								・ウォータースタンドは 月々のお支払いが定額料金のレンタル制です。
							</p>
							<p>
								・ウォータースタンドは水道水から自動で美味しいお水を作るため、必要なのはサーバーのレンタル料金だけです。ボトルウォーターのように、都度の購入費用はかかりません。
							</p>
							<Btn c='big link c-w mt40' to='/price-list'>
								<BtnLabel>
									<Font n='jp1'>機種別料金表</Font>
								</BtnLabel>
							</Btn>
						</SubSectionWIcon>
						<SubSectionWIcon icon='checkbox' title={'レンタル料金に含まれるもの'}>
							<p>
								以下は、すべて月々お支払い頂くレンタル料金に含まれます。
							</p>
							<div className='box bg-color lgreen mt20'>
								<ul className='dotted'>
									<li>フィルター交換（6ヶ月毎）</li>
									<li>定期メンテナンスサービス（6ヶ月毎）</li>
									<li>製品保証（故障修理対応、経年劣化時の本体交換）</li>
									<li>引っ越し等の取り外し・再設置の場合の移設費用</li>
								</ul>
							</div>
							<div className='box bg-color grey mt20'>
								<ul className='dotted'>
									<NonincludedServices/>
								</ul>
							</div>
							<p className='mt30'>
								また、ウォータースタンドを利用するにあたって発生する<strong>水道料金と電気料金</strong>は別途必要になります。
							</p>
							<ul className='notes'>
								<li>
									※ サーバーのタイプやご利用条件、水道や電気のご契約状況により金額は異なります。
								</li>
							</ul>
						</SubSectionWIcon>
						<SubSectionWIcon icon='checkbox' title={'お支払い方法'}>
							<p>
								・月々のお支払いは<strong>クレジットカード</strong>での決済となります。
							</p>
							<p className='note'>
								※ 法人のお客さまは銀行振込・その他お支払い方法も可能。詳細については、お問合せ・お申込みの際にご案内いたします。
							</p>
							<p className='mt10'>
								・ご利用可能なクレジットカードは以下のとおりです。
							</p>
							<CreditCards className='mt20'/>
						</SubSectionWIcon>
						<SubSectionWIcon icon='checkbox' title={'お支払いの開始'}>
							<p>
								・設置完了日よりレンタル料金が発生します。
							</p>
							<p>
								・月の途中からのご契約の場合、初月のレンタル料金は日割り計算となります。次月以降は、毎月1日に当月分のレンタル料金が発生します。
							</p>
						</SubSectionWIcon>
					</section>
					<section className='link'>
						<div className="container">
							<ReturnTopBtn/>
						</div>
					</section>
				</div>
			</div>
		)
	}

	useEffect(() =>
	{
		document.title = getDocumentTitle(MENU.ORDER, getTabNameFromKey(tabID, tabs));
		setScreenTitle(MENU.ORDER);
	}
	, [tabID]);

	useEffect(() =>
	{
		const config = {};
		if (!hasFooterUI) config.hasFooterHelp = false;
		screenConfig(config);
		
		setBack('/');
		saveQuery();
	}
	);

	const onComplete = () =>
	{
	}

	return (
		<Screen id='help-order' has-footer-ui={hasFooterUI?true:false} onComplete={onComplete} {...props}>
			<ScreenTabs n={'order'} tabs={tabs} className='has-tip-up'/>
		</Screen>
	);
}

export default ScreenHelpOrder;