import { Link } from 'react-router-dom';
import { LABELS, WATER } from '../_Params';
import { Notes } from './_UI_Common';
import { convertSpecValue, matchNumber, spBR, convertPrefecture } from '../_utils/_Utils';
import parse from 'html-react-parser';
import classNames from 'classnames/bind';
import settings from '../../json/settings.json';

export const ItemCardWrapper = (props) =>
{
	if (!props.to)
	{
		return (
			<div className='card-item item' data-type={props.type} data-style={props.style}>
				{props.children}
			</div>
		);
	}
	else
	{
		return (
			<Link className='card-item item link' data-type={props.type} data-style={props.style} to={props.to}>
				{props.children}
			</Link>
		);
	}
}

export const ItemCard = (props) =>
{
	const e = props.data;

	let style = props.style || 'list';
	let type = props.type;

	return (
		<ItemCardWrapper type={type} style={style} to={props.to}>
			<div className='col image'>
				<figure>
					<img src={e.img} alt={e.name}/>
				</figure>
				{props['has-temp'] && (<WaterTemp c='h s' data={e}/>)}
			</div>
			<div className='col info'>
				<div className='item-name'>
					{e.name}
				</div>
				<div className='item-lead'>
					{e['list-lead']}
				</div>
				<div className='description'>
					{e['list-desc']}
				</div>
				{props['has-temp'] && (<WaterTemp c='h' data={e}/>)}
			</div>
		</ItemCardWrapper>
	);
}

export const ItemPriceCard = (props) =>
{
	const e = props.data;

	let type = props.type;
	let notes = props.notes;

	const addNotes = (key) =>
	{
		if (notes === null || !notes[key] || notes[key].length === 0) return null;
		return (<Notes notes={notes[key]} />);
	}

	return (
		<div className='card-item price' data-type={type}>
			<div className='col image' data-key='name'>
				<figure>
					<img src={e.img} alt={e.name}/>
				</figure>
				<div className='item-name'>
					{spBR(e.name)}
				</div>
				<WaterTemp c='h s' data={e}/>
			</div>
			<div className='col features' data-key='features'>
				<div className="inner">
					<Features c={'v no-b'} data={e}/>
				</div>
				{addNotes('features')}
			</div>
			<div className='col price standard' data-key='price-standard'>
				<div className="inner">
					<div className="price-w-installation">
						<div className="item">
							<Price value={e['price-wtax-standard']} period={true}/>
						</div>
						<div className="installation">
							<p className='icon plus'/>
							<p className='label'>{LABELS.PRICE_INSTALLATION}</p>
							<Price c='ss' value={settings.prices.installation}/>
						</div>
					</div>
				</div>
				{addNotes('price')}
			</div>
		</div>
	);
}

export const ItemShowroomCard = (props) =>
{
	const e = props.data;

	let notes = props.notes;

	const addNotes = (key) =>
	{
		if (notes === null || !notes[key] || notes[key].length === 0) return null;
		return (<Notes notes={notes[key]} />);
	}

	let address = convertPrefecture(e.prefecture) + e.address;

	return (
		<div className='card-item showroom'>
			<div className='col image' data-key='name'>
				<figure style={{backgroundImage: 'url("' + '/img/' + e['main-img'] + '")'}}/>
			</div>
			<div className='col info' data-key='info'>
				<div className="inner">
					<div className='name'>
						{e.name}
					</div>
					<div className='full-address'>
						<span className="zip">{'〒' + e.zip}</span>
						<span className="address">{address}</span>
					</div>
				</div>
			</div>
		</div>
	);
}

export const WaterTemp = (props) =>
{
	const e = props.data;

	const keys = ['w-cold', 'w-room', 'w-hot'];
	const icons = ['water-cold', 'water-room', 'water-hot'];
	const labels = [WATER.COLD, WATER.ROOM, WATER.HOT];

	let items = [];

	for (let i = 0; i < keys.length; i++)
	{
		items[i] =
		(
			<li key={keys[i]} className={'temp-item ' + keys[i]} data-is={(e[keys[i]])?1:0}>
				<div className='icon'>
					<p className={'icon ' + icons[i]}/>
				</div>
				{props['has-label'] &&
					<p className='label'>{labels[i]}</p>
				}
			</li>
		);
	}

	return (
		<div className={'water-temp ' + props.c}>
			<ul className='temps'>
				{items}
			</ul>
		</div>
	);
}

export const Features = (props) =>
{
	const e = props.data;

	let items = [];

	let features = [];
	if (e.features !== undefined) features = e.features.split(/\//);

	for (let i = 0; i < features.length; i++)
	{
		items[i] =
		(
			<li key={i} className={'feature-item'}>
				<p className={'label'}>{features[i]}</p>
			</li>
		);
	}

	return (
		<ul className={classNames('features-list', props.c)}>
			{items}
		</ul>
	);
}

export const Price = (props) =>
{
	let value = props.value.toLocaleString();
	let unit = '円';
	let tax = (props.tax === undefined)?'（税込）':'';
	let period = (props.period)?'/ 月':'';

	return (
		<p className={classNames('price', props.c)}>
			{props.plus && <span className='plus'>+</span>}
			<span className='value'>{value}</span>
			<span className='unit'>{unit}</span>
			{tax && <span className='tax'>{tax}</span>}
			{period && <span className='period'>{period}</span>}
		</p>
	);
}

export const Specs = (props) =>
{
	const e = props.data;
	const keys = props.keys;

	let items = [];

	const size_keys = ['w', 'd', 'h'];
	const temp_keys = ['cold', 'room', 'hot'];
	const wattage_keys = ['pump', 'compresser', 'heater'];

	const water_labels = [WATER.COLD, WATER.ROOM, WATER.HOT];
	const wattage_labels = ['ポンプ', 'コンプレッサー', 'ヒーター'];

	const brsp = '<br className="space"/>';
	const times = '<span className="times">x</span>';

	const dish_label_note = '<p className="note"><>は受け皿使用時</p>';
	const dish_value_note = '<p className="note">※上記サイズ以外に、製品両サイドと背面は壁から数cm開ける必要がございます。</p>';

	const mapKeysArray = (keys, key_header, unit, labels, valueFunc) =>
	{
		let ary = [];

		for (let i = 0; i < keys.length; i++)
		{
			if (keys[i] === undefined) continue;

			let v = e[key_header + keys[i]];
			if (v === undefined) continue;

			if (valueFunc) v = valueFunc(v);

			if (unit) v = convertSpecValue(v, unit);
			if (labels && labels.length > 0 && labels[i] !== '') v = labels[i] + '：' + v;

			ary.push(v);
		}

		return ary;
	}

	const waterTempValues = () =>
	{
		const keys = [['cold'], ['hot1', 'hot2', 'hot3']];
		const labels = [[''], ['高', '中', '低']];
		const labels2 = ['冷水', '温水'];

		return keys.map((ary, ai) => 
		{
			const unit = '℃';
			let hasTemp = false;

			let value = mapKeysArray(ary, 'temp-w-', '', labels[ai], (v) =>
			{
				v = convertSpecValue(v, unit);
				if (v.indexOf(unit) > -1) hasTemp = true;
				return v.replace(/\-/g, '～');
			}
			);

			if (!value || value.length == 0) return null;

			return (hasTemp?'[' + labels2[ai] + '] ':'') + value.join(' ');
		}
		).join(brsp);
	}

	const waterSizeValues = (key_header) =>
	{
		if (!size_keys.every((key) => e[key_header + key] != undefined)) return null;

		return size_keys.map((key, i) =>
		{
			return e[key_header + key].toString().replace(/\(/g, '<').replace(/\)/g, '>') + 'mm (' + key.toUpperCase() + ')';
		}
		).join(times);
	}

	for (let i = 0; i < keys.length; i++)
	{
		let k = keys[i];

		let name = k.name;
		let value = '';

		let has_dish = false;

		switch(k.key)
		{
			case 'capacity':
				value = mapKeysArray(temp_keys, 'capacity-', 'L', water_labels);
				if (value.length == 0) value = e['capacity-total'];
				else value = value.join(brsp);
			break;
			case 'size':
				value = waterSizeValues('size-');
				if (value)
				{
					has_dish = !size_keys.every((key) => !/[\(\)]/.test(e['size-' + key]));
				}	
			break;
			case 'space':
				value = waterSizeValues('space-');
				if (value)
				{
					value += dish_value_note;
					has_dish = !size_keys.every((key) => !/[\(\)]/.test(e['space-' + key]));
				}
			break;
			case 'weight':
				value = e[k.key] + 'kg';
			break;
			case 'wattage':
				value = mapKeysArray(wattage_keys, 'wattage-', 'W', wattage_labels);
				value = value.join(brsp);
			break;
			case 'temp':
				value = waterTempValues();
			break;
			default:
				value = e[k.key];
			break;
		}

		if (has_dish) name += dish_label_note;

		if (!value) continue;
		
		items[i] =
		(
			<dl key={i} className={k.c}>
				<dt>{parse(name)}</dt>
				<dd>{parse(value)}</dd>
			</dl>
		);
	}

	return (
		<div className='specs-list'>
			{items}
		</div>
	);
}