import React, { useEffect } from 'react'
import { MENU, URLS } from '../_Params';
import { Screen, Font, Btn, BtnLabel, RoundBtn, RectBtn, HelpBtn } from '../_sections/_UI_Common';
import { TopSlider, CatalogBtn, ThumbVideoBtn } from '../_sections/_UI_Top';
import { getDocumentTitle, getTabNameFromKey, getItemsByID } from '../_utils/_Utils';
import { useScreenConfig } from '../_utils/_Hooks';
import Slider from "react-slick";
import settings from '../../json/settings.json';

const ScreenTop = (props) =>
{
	const {screenConfig} = useScreenConfig();

	//Get Catalog Items
	const catalog_items = getItemsByID(settings.top.catalog.items, ['name', 'img'], true);

	//Get Videos
	const video_btns = [];

	let vl = settings.top.videos.length;

	for (let i = 0; i < vl; i++)
	{
		let e = settings.top.videos[i];
		let btn = (<ThumbVideoBtn key={i} n={'video-' + (i + 1)} title={e.title} img={e.img} video-id={e.id}/>);
		video_btns.push(btn);
	}

	let videos = null;
	let slider = null;

	if (vl <= 2)
	{
		videos = (
			<div className="container">
				<div className="btns-wrap fixed">
					{video_btns}
				</div>
			</div>
		)
	}
	else
	{
		const params =
		{
			infinite: true,
			autoplay: true,
			autoplaySpeed: 5000,
			speed: 900,
			//cssEase: "linear",
			slidesToShow: 2,
			//slidesToScroll: 1,
			arrows: false,
			dots: false,
			swipe: true,
			pauseOnFocus: true,
    		pauseOnHover: true,
			variableWidth: true,
			centerMode: true,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
					}
				},
			]
		};

		videos = (
			<div className="btns-wrap slider">
				<Slider ref={c => (slider = c)} {...params}>{video_btns}</Slider>
			</div>
		)
	}

	const documentTitle = getDocumentTitle();

	useEffect(() =>
	{
		document.title = documentTitle;

		screenConfig({hasFooterHelp: false});
	}
	);

	const returnURL = window.location.origin;
	//const help_url = URLS.LIVECALL + encodeURI(returnURL);
	const help_url = URLS.LIVECALL;

	return (
		<Screen id='top' has-header={false} has-footer-ui={false} {...props}>
			<TopSlider imgs={settings.top.kv.imgs}/>
			<main>
				<section className="main">
					<div className="section-row catalog">
						<div className="container">
							<CatalogBtn items={catalog_items} to='/catalog'/>
						</div>
					</div>
					<div className="section-row videos">
						{videos}
					</div>
				</section>
				<section className="footer">
					<div className="section-row links-1">
						<div className="container">
							<RectBtn to='/setup'>
								<span>設置方法・</span>
								<br className="pc-only"/>
								<span>サポートサービス</span>
							</RectBtn>
							<RectBtn to='/order'>
								<span>お申し込み・</span>
								<br className="pc-only"/>
								<span>料金について</span>
							</RectBtn>
							<RectBtn to='/price-list'>{MENU.PRICELIST}</RectBtn>
						</div>
					</div>
					<div className="section-row links-2">
						<div className="container">
							<RectBtn c='showroom' icon='map-marker' to='/showroom'>{MENU.SHOWROOM}</RectBtn>
							<RectBtn c='help' icon='question' href={help_url}>{MENU.HELP}</RectBtn>
						</div>
					</div>
				</section>
			</main>
		</Screen>
	);
}

export default ScreenTop;